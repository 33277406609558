import { useContext } from "react";
import { AppContext } from "../../context/AppContext";

import "../../index.css"

export const Footer = () => {

  const [state] = useContext(AppContext);

  return (
            <footer className={`${state.darkMode?'':'page_bg_color sections_title_color'} shadow dark:bg-gray-900 mb-0`}>
                <div className="w-full max-w-screen-xl mx-auto p-4 max-md:text-center">
                    <hr className={`${state.darkMode?'':'sections_border_color'} my-3 sm:mx-auto dark:border-gray-700`} />
                    <span className={`${state.darkMode?'':'footer_text_color'} block text-sm sm:text-center dark:text-gray-400`}>© 2024 Powered by <a href="https://www.hostpms.com/" target="_blank" rel='noreferrer' className="hover:underline">Host</a>. All Rights Reserved.</span>
                </div>
            </footer>
  )
}
