import { useTranslation } from "react-i18next";

/**
 * @module Nationality
 * @description Component for selecting a guest's nationality.
 * This component provides a dropdown to select a nationality,
 * indicating whether it is a required field based on the provided validation rules.
 * It also handles changes in nationality and validates the NIF based on the selected nationality.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {function} props.validateNifonBlur - Function to validate the NIF when the nationality is changed.
 * @param {function} props.handleGuestChange - Function to handle changes in guest data.
 * @param {Object} props.guest - The guest object containing nationality data.
 * @param {Object} props.nifValues - The current NIF values for guests, indexed by guest ID.
 * @param {Object} props.initialData - Contains initial data such as country list.
 * @param {Array<Object>} props.requiredFields - List of required fields for validation,
 *                                               where each object contains:
 *                                               - {string} Field - The field name.
 *                                               - {boolean} IsMandatory - Indicates if the field is required.
 *
 * @returns {JSX.Element} The rendered dropdown for selecting the nationality.
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @version 1.0.0
 *
 * @example
 * // Example usage of the Nationality component
 * <Nationality
 *   validateNifonBlur={validateNifonBlur}
 *   handleGuestChange={handleGuestChange}
 *   guest={guestData}
 *   nifValues={nifValues}
 *   initialData={initialData}
 *   requiredFields={requiredFields}
 * />
 */
export const Nationality = ({validateNifonBlur, handleGuestChange, guest, nifValues, initialData, requiredFields}) => {
  const [t] = useTranslation("global");

    // Check if the NationalityId field is required based on the provided validation rules
    const isRequiredId = requiredFields.some(
      field => field.Field === "NationalityId" && field.IsMandatory);

    // Check if the NationalityIso field is required based on the provided validation rules
    const isRequiredIso = requiredFields.some(
      field => field.Field === "NationalityIso" && field.IsMandatory);

      /**
     * @description Handles changes in the nationality dropdown selection.
     * This function updates the guest's NationalityId and NationalityIso based on the selected country,
     * and validates the NIF based on the newly selected NationalityIso.
     *
     * @function handleNationalityChange
     * @param {Object} e - The event object from the dropdown change.
     * @param {string} id - The ID of the guest being updated.
     */
    const handleNationalityChange = (e, id) => {
        // Parse the selected country's data from the dropdown value
        const selectedCountry = JSON.parse(e.target.value);
        // Update the NationalityId and NationalityIso in the guest data
        handleGuestChange(id, 'NationalityId', selectedCountry.Id);
        handleGuestChange(id, 'NationalityIso', selectedCountry.IsoCode);

        // After changing the nationality, validate the guest's NIF with the new NationalityIso
        const currentNif = nifValues[id] || "";
        validateNifonBlur(currentNif, selectedCountry.IsoCode, id);
      };

  return (
    <div>
    <label htmlFor="nacionalidade" className="pl-2 pr-2 rounded-xl inline-block translate-y-3.5 text-center justify-center translate-x-3 bg-white text-sm font-medium text-gray-700 mb-1" >{`${t(`Nationality.nationality`)}`}<span className="text-gray-400 ml-2">{isRequiredId? "": `${t(`FormFields.optional`)}`}</span></label>
    <div className="">
      <select name="NationalityId" required={isRequiredId}  onChange={(e) => handleNationalityChange(e, guest.id)}  value={JSON.stringify({ Id: guest.data.NationalityId || "", IsoCode: guest.data.NationalityIso || "" })} id="nacionalidade" className="w-full px-4 py-2 rounded-lg border border-gray-700 focus:ring-2 text-xl focus:border-blue-500 transition duration-150 ease-in-out appearance-none" >
      <option value="" disabled>Seleccione</option>
      {initialData.CountryList.map((country) => (
        <option key={country.Id} value={JSON.stringify({ Id: country.Id, IsoCode: country.IsoCode })} >
           {country.TranslatedDescription}
        </option> ))}
      </select>
    </div>
  </div>  )
}
