import { useTranslation } from "react-i18next";
import { AppContext } from "../../../context/AppContext";
import { useContext, useEffect, useState } from "react";
import { WellnessServicesListCard } from "./WellnessServicesListCard";
import { WellnessServicesFilter } from "./WellnessServicesFilter";

export const WellnessServicesList = ({serviceWellness}) => {
  const [t] = useTranslation("global");
  const [state] = useContext(AppContext);
  const [products, setProducts] = useState([...serviceWellness.products]); // eslint-disable-line
  const [productsTypes, setProductsTypes] = useState([]);
  const [activeProducts, setActiveProducts] = useState([]);

    // Retorna os menus consoante o filter
    const filteredProducts = products.filter((order) => {return order.productType===activeProducts});

  useEffect(() => {
    function getUniqueProductsTypes(products) {
      // Usar um Set para armazenar os tipos de menu únicos
      const productsTypesSet = new Set();

      // Itera sobre o array de menus
      products.forEach(productItem => {
        productsTypesSet.add(productItem.productType);
      });

      // Converte o Set para um array e o retorna
      return Array.from(productsTypesSet);
    }

    if (serviceWellness && serviceWellness.products) {
      const uniqueProductsTypes = getUniqueProductsTypes(serviceWellness.products);
      setProductsTypes(uniqueProductsTypes);
    }
  }, [serviceWellness]);

  useEffect(() => {
    if (serviceWellness && serviceWellness.products) {
      setProducts(serviceWellness.products);
    }
  }, [serviceWellness]);

  useEffect(() => {
    if (productsTypes.length > 0) {
      setActiveProducts(productsTypes[0]);
    }
  }, [productsTypes]);

  console.log(activeProducts)

  return (
    <section className={`${ state.darkMode ? "" : "card_bg_color card_border_color" } mx-4 mt-4 mb-4 px-5 py-3 border rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 max-w-full`} >
        <div className="flex justify-between mb-2 ">
            <p className={`${ state.darkMode ? "" : "card_title_color" } font-normal dark:text-white text-2xl mb-2 `} > <b>{`${t("menu")}`}</b> </p>
            <button type="button" className={`${ state.darkMode ? "" : "card_title_color card_border_color card_hover_bg_color" } px-3 flex py-2 text-sm font-medium border rounded-lg focus:z-10 bg-white dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-primary-500 dark:focus:text-white`} >
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-clipboard2-check" viewBox="0 0 16 16">
                <path d="M9.5 0a.5.5 0 0 1 .5.5.5.5 0 0 0 .5.5.5.5 0 0 1 .5.5V2a.5.5 0 0 1-.5.5h-5A.5.5 0 0 1 5 2v-.5a.5.5 0 0 1 .5-.5.5.5 0 0 0 .5-.5.5.5 0 0 1 .5-.5z"/>
                <path d="M3 2.5a.5.5 0 0 1 .5-.5H4a.5.5 0 0 0 0-1h-.5A1.5 1.5 0 0 0 2 2.5v12A1.5 1.5 0 0 0 3.5 16h9a1.5 1.5 0 0 0 1.5-1.5v-12A1.5 1.5 0 0 0 12.5 1H12a.5.5 0 0 0 0 1h.5a.5.5 0 0 1 .5.5v12a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5z"/>
                <path d="M10.854 7.854a.5.5 0 0 0-.708-.708L7.5 9.793 6.354 8.646a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0z"/>
            </svg>
            <span className="ml-2">{`${t("book_a_table")}`}</span>
            </button>
        </div>
      <WellnessServicesFilter productsTypes={productsTypes} activeProducts={activeProducts} setActiveProducts={setActiveProducts} />
      <section className="w-full mb-2">
      {filteredProducts.length > 0 ? (
        filteredProducts.map((order) => (
          <WellnessServicesListCard key={order.productId} productId={order.productId} productTitle={order.productTitle} productDescription={order.productDescription} productPrice={order.productPrice} />
        )) ) : (
        <p>{t("no_items_available")}</p>  // Handle empty state
      )}
      </section>
    </section>
  );
};
