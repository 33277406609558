import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { ToastContainer } from "react-toastify";
import React from "react";
import App from "./App";


import { ScrollToTop } from "./components";
import { Store } from "./context/AppContext";
import { RefsProvider } from "./context/RefsContext";


import global_en_us from "../src/translations/en_us/global.json";
import global_pt_pt from "../src/translations/pt_pt/global.json";
import i18next from "i18next";
import { I18nextProvider } from "react-i18next";
import register from "./swDev";


import "react-toastify/dist/ReactToastify.css";
import 'react-loading-skeleton/dist/skeleton.css'
import "./index.css";

i18next.init({
  interpolation: { escapeValue: true },
  lng: "en_us",
  resources: {
    en_us: {
      global: global_en_us,
    },
    pt_pt: {
      global: global_pt_pt,
    },
  },
});
register()


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RefsProvider>
      <I18nextProvider i18n={i18next}>
        <BrowserRouter forceRefresh={true}>
          <Store>
            <ScrollToTop />
            <ToastContainer autoClose={3000} position={"bottom-right"} />
            <App />
          </Store>
        </BrowserRouter>
      </I18nextProvider>
    </RefsProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
