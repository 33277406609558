import { DetailSlider } from "./components/DetailSlider";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEffect, useContext, useState } from "react";
import { toast } from "react-toastify";
import { Navbar, Sidebar, Footer } from "../../components/";
import { getReservationByDetailId, getHotelById } from "../../services";
import { useCookies } from "react-cookie";
import { AppContext } from "../../context/AppContext";
import { ReservationDetailsSkeleton } from "./components/ReservationDetailsSkeleton";
import { Loading } from "../Loading";
import { ReservationDetails } from "./components/ReservationDetails";

require("dayjs/locale/pt");

export const Reserva = () => {
  const { platformUid, detailId } = useParams();
  const [state, dispatch] = useContext(AppContext);
  const [t] = useTranslation("global");
  const [loadingReservation, setLoadingReservation]= useState(true)
  const [reservation, setReservation] = useState()
  const [hotel, setHotel] = useState()
  const [cookies] = useCookies(['sUid']);

  const fetchReservations = async () => {
    try {
      setLoadingReservation(true);
      const result = await getReservationByDetailId(detailId, hotel);
      setReservation(result[0]);

    } catch (error) {
      console.log(error)
      toast.error("Unable to get reservations!");
    } finally {
      setLoadingReservation(false);
    }
  }

  useEffect(() => {
    getHotelById(platformUid).then((data) => {
       setHotel(data)
    }).catch(error => {
      console.error("Error fetching hotel info:", error);
      toast.error("Unable to get hotel info!");
    });
  }, [platformUid]);

  useEffect(() => {
    if (hotel) {
      // Despacha a ação apenas quando principalHotel for atualizado
      dispatch({ type: "SET_CURRENT_HOTEL", payload: hotel });
    }
  }, [hotel]); // eslint-disable-line

  useEffect(() => {
    if(hotel){
    fetchReservations();
    }
  }, [hotel, cookies.profileKey]); // eslint-disable-line

  if (!state.currentHotel) {
    return <Loading />;
  }

  return (
    (hotel && reservation &&
    <div className={`${ state.darkMode ? "" : "page_bg_color" } antialiased dark:bg-gray-900`} >
      <Navbar />
      <Sidebar />
      { state.userDetails &&
      <div className="h-auto pt-[61px] md:ml-[255px]"> <section className={`${ state.darkMode ? "" : "card_bg_color card_border_color" } mx-4 mt-4 mb-4 px-5 py-3 border rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 max-w-full`} >
          <h5 className={`${ state.darkMode ? "" : "card_title_color" } mb-3 text-2xl font-semibold tracking-tight dark:text-white`} > {hotel.name2} </h5>
          <div className="w-full">
            <div className="flex max-sm:flex-wrap w-full">
              <div className="w-3/5 max-sm:w-full">
                <DetailSlider reservation={reservation} />
                <div className="pb-3 border-b-2">
                  <p className={`${ state.darkMode ? "" : "card_title_color" } mt-5 font-normal dark:text-white text-lg`} > <b>{reservation.categoryUpgradeFrom}</b> </p>
                  <p className={`${ state.darkMode ? "" : "card_text_color" } font-normal dark:text-gray-400 text-lg`} >{`${t(`breakfast_included`)}`}</p>
                </div>
                <div className="pb-3 border-b-2 border-gray-500">
                  <p className={`${ state.darkMode ? "" : "card_title_color" } my-3 font-normal dark:text-white text-lg`} > <b>{`${t(`amenities`)}`}</b> </p>
                  {/* FAZER UM MAP DAS AMENITIES*/}
                  <div className={`${ state.darkMode ? "" : "card_text_color" } flex flex-wrap`} >
                    <p className={`${ state.darkMode ? "" : "card_hover_bg_color" } font-bold mr-3 text-base rounded-xl p-1 mb-2`} >{`${t(`mini_bar`)}`}</p>
                    <p className={`${ state.darkMode ? "" : "card_hover_bg_color" } font-bold mr-3 text-base rounded-xl p-1 mb-2`} >{`${t(`wi_fi`)}`}</p>
                    <p className={`${ state.darkMode ? "" : "card_hover_bg_color" } font-bold mr-3 text-base rounded-xl p-1 mb-2`} >{`${t(`tv`)}`}</p>
                    <p className={`${ state.darkMode ? "" : "card_hover_bg_color" } font-bold mr-3 text-base rounded-xl p-1 mb-2`} >{`${t(`balcony`)}`}</p>
                    <p className={`${ state.darkMode ? "" : "card_hover_bg_color" } font-bold mr-3 text-base rounded-xl p-1 mb-2`} >{`${t(`bathtub`)}`}</p>
                    <p className={`${ state.darkMode ? "" : "card_hover_bg_color" } font-bold mr-3 text-base rounded-xl p-1 mb-2`} >{`${t(`hairdryer`)}`}</p>
                  </div>
                </div>
              </div>
              {loadingReservation && state.userDetails? (
                <ReservationDetailsSkeleton />
            ) : (
              <ReservationDetails reservation={reservation} />)
            }
            </div>
          </div>
          <div></div>
        </section>
        <Footer />
      </div>
      }
    </div>
    )
  );
};
