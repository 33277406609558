
const ProgressBar = ({ step, totalSteps }) => {

  const progress = (step / totalSteps) * 100;

  return (
    <div className="flex items-center space-x-2 w-full max-w-md max-sm:px-4 py-6">
      <div className="flex-1">
        <div className="w-full bg-gray-300 rounded-full h-2.5">
          <div
            className="bg-yellow-400 h-2.5 rounded-full"
            style={{ width: `${progress}%` }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default ProgressBar;