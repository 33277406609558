import { useEffect, useState, useContext } from 'react'
import { useParams} from 'react-router-dom'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { useCookies } from 'react-cookie'

import { Navbar, Sidebar, Footer } from '../../components/'
import { getHotelById } from '../../services'
import { AppContext } from '../../context/AppContext'
import { Loading } from '../Loading'
import { WelcomeMessage } from './components/WelcomeMessage'
import { AdministrationMessage } from './components/AdministrationMessage'
import { HotelReservationsSection } from './components/HotelReservationsSection'

export const HotelOverview = () => {

  const [state, dispatch] = useContext(AppContext);
  const [principalHotel, setPrincipalHotel] = useState();
  const {platformUid} = useParams();
  const [t] = useTranslation("global")
  const [cookie, setCookie] = useCookies(['platformUid']); // eslint-disable-line

  useEffect(() => {
    getHotelById(platformUid).then((data) => {
    setPrincipalHotel(data)
    }).catch(error => {
      console.error("Error fetching hotel info:", error);
      toast.error("Unable to get hotel info!");
    });
  }, []); // eslint-disable-line

  useEffect(() => {
    if (principalHotel) {
      // Despacha a ação apenas quando principalHotel for atualizado
      dispatch({ type: "SET_CURRENT_HOTEL", payload: principalHotel });
      setCookie('platformUid', platformUid, {path:"/"})
    }
  }, [principalHotel]); // eslint-disable-line

  if (!state.currentHotel) {
    return <Loading />;
  }

    return (
      <>
        <div className={`antialiased ${state.darkMode?'':'page_bg_color'} dark:bg-gray-900`}>
          <Navbar  />
          <Sidebar  />
          { state.userDetails &&
          <div className='h-auto pt-[61px] md:ml-[255px] flex flex-col min-h-screen'>
          <div className='mx-4 pt-4 flex-grow'>
              <div className="">
                <WelcomeMessage />
                <AdministrationMessage />
              <section className="mb-4 ">
                <div className={`px-5 py-3 ${state.darkMode?'':'sections_title_color sections_bg_color sections_border_color'} border border-gray-300 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 max-w-full`}>
                  <h5 className="pb-3 text-2xl font-bold tracking-tight dark:text-white">{t("reservations")}</h5>
                  <div className='flex flex-wrap'>
                    <HotelReservationsSection hotel={principalHotel} />
                  </div>
                </div>
              </section>
            </div>
          </div>
          <Footer />
          </div>
          }
          </div>
    </>

  )
}
