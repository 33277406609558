import { Navbar, Sidebar, Footer } from "../../components/";
import { getHotelPmsIdPrefix} from "../../services";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../context/AppContext";
import { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { ReservationsSection } from "./components/ReservationsSection";
import { CookieConsent } from "./components/CookieConsent";
import { useCookies } from "react-cookie";

export const Overview = () => {
  const [t] = useTranslation("global");

  //primeiros 8 digitos correspondentes ao Guid do hotel
  const { platformUid, sUid } = useParams();
  //sUid do host

  const [principalHotel, setPrincipalHotel] = useState();
  const [groupHotels, setGroupHotels] = useState([]);
  const [reservations, setReservations] = useState([]);
  const [state, dispatch] = useContext(AppContext);
  const [cookie, setCookie] = useCookies(['platformUid', 'sUid', 'profileKey', 'cultureCode']); // eslint-disable-line

  const findPrincipalHotel = (groupHotels) => {
    // Loop através da lista de hotéis
    for (const hotel of groupHotels) {
      // Extrai os primeiros 8 dígitos do pmsId
      const pmsIdPrefix = hotel.pmsId.slice(0, 8);
      // Verifica se o prefixo do pmsId corresponde ao platformId
      if (pmsIdPrefix === platformUid) {
        setPrincipalHotel(hotel);
        break; // Encerra o loop após encontrar o hotel correspondente
      }
    }
  };

  useEffect(() => {
    setCookie('platformUid', platformUid, {path:"/"})
    setCookie('sUid', sUid, {path:"/"})

    getHotelPmsIdPrefix(platformUid)
      .then((result) => {
        const data = result;
        // Define a lista de hoteis do grupo
        setGroupHotels(data);
        return data;
      })
      .then((data) => {
        // Define o hotel principal da reserva
        findPrincipalHotel(data);
      })
      .catch((error) => {
        toast.error("Unable to get hotels!");
      });
  }, [platformUid]); // eslint-disable-line

  useEffect(() => {
    if (principalHotel) {
      // Despacha a ação apenas quando principalHotel for atualizado
      dispatch({ type: "SET_CURRENT_HOTEL", payload: principalHotel });
      setCookie('cultureCode', principalHotel.defaultCultureCode, {path:"/"})
    }

  }, [principalHotel]); // eslint-disable-line

  console.log(principalHotel)

  return (
    <>
        <div className={`${ state.darkMode ? "" : "page_bg_color" } antialiased dark:bg-gray-900`} >
          {state.currentHotel &&
          <Navbar />
          }
          <Sidebar />
          <div className="h-auto pt-[61px] md:ml-[255px] flex flex-col min-h-screen">
            <div className="mx-4 pt-4 flex-grow">
              <section className="">
                <div className={`${ state.darkMode ? "" : "sections_bg_color sections_border_color" } px-5 py-3 border rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 max-w-full`} >
                  <h5 className={`${ state.darkMode ? "" : "sections_title_color" } pb-3 text-2xl font-bold tracking-tight dark:text-white`} > {t("reservations")} </h5>
                  <ReservationsSection reservations={reservations} groupHotels={groupHotels} setReservations={setReservations} />
                </div>
              </section>
            </div>
            <Footer />
            <CookieConsent />
          </div>
        </div>
    </>
  );
};
