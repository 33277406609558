import {AllRoutes} from "./routes/AllRoutes"
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";


import './App.css';

function App() {

  const [t] = useTranslation("global")

  dayjs.locale(t("dayjs_locale"))

  return (
    <div className="App">
      <AllRoutes />
    </div>
  );
}


export default App;
