import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import { Sidebar, Navbar, Footer } from "../../components";
import { HostFormComplete } from "./components/HostFormComplete";
import { toast } from "react-toastify";
import { getHotelById, searchReservation } from "../../services";
import { useCookies } from "react-cookie";
import { Loading } from "../Loading";
import { useTranslation } from "react-i18next";

/**
 * @module OnlineCheckIn
 * @description Component for handling the online check-in process, fetching hotel and reservation data.
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @version 1.0.0
 * @example
 * <OnlineCheckIn />
 */
export const OnlineCheckIn = () => {
  const [state, dispatch] = useContext(AppContext);
  const { platformUid, detailId, resNo } = useParams();
  const [principalHotel, setPrincipalHotel] = useState();
  const [reservation, setReservation] = useState();
  const [cookies, setCookie] = useCookies(["cultureCode", "GUid"]); // eslint-disable-line
  const [t] = useTranslation("global");

  /**
   * @events useEffectFetchHotel
   * @description Fetches hotel information by ID when the component mounts.
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @version 1.0.0
   * @example
   * useEffect(() => {
   *   getHotelById(platformUid).then(data => {
   *     setPrincipalHotel(data);
   *   }).catch(error => {
   *     console.error("Error fetching hotel info:", error);
   *     toast.error("Unable to get hotel info!");
   *   });
   * }, []);
   */
  useEffect(() => {
    getHotelById(platformUid)
      .then((data) => {
        setPrincipalHotel(data);
      })
      .catch((error) => {
        console.error(`${t(`OnlineCheckIn.errorhotel`)}`, error);
        toast.error(`${t(`OnlineCheckIn.errorhotel`)}`);
      });
  }, []); // eslint-disable-line

  /**
   * @events useEffectDispatchHotel
   * @description Dispatches the current hotel information to the context when it is updated.
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @version 1.0.0
   * @example
   * useEffect(() => {
   *   if (principalHotel) {
   *     dispatch({ type: "SET_CURRENT_HOTEL", payload: principalHotel });
   *   }
   * }, [principalHotel]);
   */
  useEffect(() => {
    if (principalHotel) {
      // Despacha a ação apenas quando principalHotel for atualizado
      dispatch({ type: "SET_CURRENT_HOTEL", payload: principalHotel });
    }
  }, [principalHotel]); // eslint-disable-line

  /**
   * @events useEffectSearchReservation
   * @description Searches for a reservation based on the hotel and user cookies when the principal hotel is set.
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @version 1.0.0
   * @example
   * useEffect(() => {
   *   if (principalHotel) {
   *     searchReservation(resNo, detailId, principalHotel, cookies.GUid, cookies.cultureCode).then(data => {
   *       setReservation(data.Data.Details[0]);
   *     }).catch(error => {
   *       console.error("Error fetching reservation info:", error);
   *       toast.error("Unable to get reservation info!");
   *     });
   *   }
   * }, [principalHotel, resNo, detailId, cookies.GUid, cookies.cultureCode]);
   */
  useEffect(() => {
    if (principalHotel) {
      searchReservation( resNo, detailId, principalHotel, cookies.GUid, cookies.cultureCode )
        .then((data) => {
          setReservation(data.Data.Details[0]);
        })
        .catch((error) => {
          console.error(`${t(`OnlineCheckIn.errorreservation`)}`, error);
          toast.error(`${t(`OnlineCheckIn.errorreservation`)}`);
        });
    }
  }, [principalHotel, resNo, detailId, cookies.GUid, cookies.cultureCode]);

  if (!state.currentHotel) {
    return <Loading />;
  }

  return (
    <>
      <div className={`${ state.darkMode ? "" : "page_bg_color" } antialiased dark:bg-gray-900`} >
        {state.currentHotel && <Navbar />}
        <Sidebar />
        <div className="h-auto pt-[61px] md:ml-[255px] flex flex-col min-h-screen">
            <section className=" flex-grow">
              <HostFormComplete hotel={principalHotel} reservation={reservation} setReservation={setReservation} />
            </section>
          <Footer />
        </div>
      </div>
    </>
  );
};
