import { Navbar, Sidebar, Footer } from "../../components";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../context/AppContext";
import { useContext} from "react";
import { ColorPicker } from "./components/ColorPicker";


export const Theme = () => {

    const [t] = useTranslation("global");
    const [state] = useContext(AppContext);

  return (
    <div className="antialiased dark:bg-gray-900 flex flex-col">
      <Navbar />
      <Sidebar />
      <main className={`${state.darkMode?'':'page_bg_color'} pt-[61px] md:ml-[255px] h-min-screen flex-grow`}>
        <section className="mx-4 pt-4">
          <div className={`${state.darkMode?'':'sections_bg_color sections_border_color'} px-5 py-3 border rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 max-w-full`}>
            <h5 className={`${state.darkMode?'':'sections_title_color'} pb-1 text-2xl font-bold tracking-tight dark:text-white`}> {t("theme")} </h5>
          </div>
        </section>
        <section className={`mx-4 mt-2 ${state.darkMode?'':'sections_bg_color sections_border_color'} sm:flex sm:flex-wrap border rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 max-w-full`}>
          <div className={`sm:w-1/2 px-5 py-3 max-w-full`}>
            <p className={`${state.darkMode?'':'sections_title_color'} pb-1 text-xl font-bold tracking-tight dark:text-white`}> {t("sidebar")} </p>
            <div className={`${state.darkMode?'':'sections_text_color'} flex justify-between items-center p-2 text-base font-medium text-gray-900 rounded-lg dark:text-white group`}>
                <ul className="flex flex-col w-full">
                    <li className="flex justify-between w-full">
                        <span className="flex ml-3 text-left text-md whitespace-nowrap"> {t("title_color")} </span>
                        <ColorPicker variable={'--sidebar_title_color'} variable_state={"sidebar_title_color"} />
                    </li>
                    <li className="flex justify-between w-full">
                        <span className="flex ml-3 text-left text-md whitespace-nowrap"> {t("text_color")} </span>
                        <ColorPicker variable={'--sidebar_text_color'} variable_state={"sidebar_text_color"}/>
                    </li>
                    <li className="flex justify-between w-full">
                        <span className="flex ml-3 text-left text-md whitespace-nowrap"> {t("icons_color")} </span>
                        <ColorPicker variable={'--sidebar_icons_color'} variable_state={"sidebar_icons_color"}/>
                    </li>
                    <li className="flex justify-between w-full">
                        <span className="flex ml-3 text-left text-md whitespace-nowrap"> {t("bg_color")} </span>
                        <ColorPicker variable={'--sidebar_bg_color'} variable_state={"sidebar_bg_color"}/>
                    </li>
                    <li className="flex justify-between w-full">
                        <span className="flex ml-3 text-left text-md whitespace-nowrap"> {t("border_color")} </span>
                        <ColorPicker variable={'--sidebar_border_color'} variable_state={"sidebar_border_color"}/>
                    </li>
                    <li className="flex justify-between w-full">
                        <span className="flex ml-3 text-left text-md whitespace-nowrap"> {t("hover_bg_color")} </span>
                        <ColorPicker variable={'--sidebar_hover_bg_color'} variable_state={"sidebar_hover_bg_color"}/>
                    </li>
                    <li className="flex justify-between w-full">
                        <span className="flex ml-3 text-left text-md whitespace-nowrap"> {t("hover_text_color")} </span>
                        <ColorPicker variable={'--sidebar_hover_text_color'} variable_state={"sidebar_hover_text_color"}/>
                    </li>
                </ul>
            </div>
          </div>
          <div className={`sm:w-1/2 px-5 py-3 max-w-full`}>
          <p className={`${state.darkMode?'':'sections_title_color'} pb-1 text-xl font-bold tracking-tight dark:text-white`}> {t("navbar")} </p>
              <div className={`${state.darkMode?'':'sections_text_color'} flex justify-between items-center p-2 text-base font-medium text-gray-900 rounded-lg dark:text-white group`}>
                  <ul className="flex flex-col w-full">
                      <li className="flex justify-between w-full">
                          <span className="flex ml-3 text-left text-md whitespace-nowrap"> {t("icons_color")} </span>
                          <ColorPicker variable={'--navbar_icons_color'} variable_state={"navbar_icons_color"}/>
                      </li>
                      <li className="flex justify-between w-full">
                          <span className="flex ml-3 text-left text-md whitespace-nowrap"> {t("bg_color")} </span>
                          <ColorPicker variable={'--navbar_bg_color'} variable_state={"navbar_bg_color"}/>
                      </li>
                      <li className="flex justify-between w-full">
                          <span className="flex ml-3 text-left text-md whitespace-nowrap"> {t("border_color")} </span>
                          <ColorPicker variable={'--navbar_border_color'} variable_state={"navbar_border_color"}/>
                      </li>
                      <li className="flex justify-between w-full">
                          <span className="flex ml-3 text-left text-md whitespace-nowrap"> {t("hover_bg_color")} </span>
                          <ColorPicker variable={'--navbar_hover_bg_color'} variable_state={"navbar_hover_bg_color"}/>
                      </li>
                      <li className="flex justify-between w-full">
                          <span className="flex ml-3 text-left text-md whitespace-nowrap"> {t("hover_text_color")} </span>
                          <ColorPicker variable={'--navbar_hover_text_color'} variable_state={"navbar_hover_text_color"}/>
                      </li>
                  </ul>
              </div>
            </div>
            <div className={`sm:w-1/2 px-5 py-3 max-w-full`}>
              <p className={`${state.darkMode?'':'sections_title_color'} pb-1 text-xl font-bold tracking-tight dark:text-white`}> {t("sections")} </p>
              <div className={`${state.darkMode?'':'sections_text_color'} flex justify-between items-center p-2 text-base font-medium text-gray-900 rounded-lg dark:text-white group`}>
                  <ul className="flex flex-col w-full">
                      <li className="flex justify-between w-full">
                          <span className="flex ml-3 text-left text-md whitespace-nowrap"> {t("title_color")} </span>
                          <ColorPicker variable={'--sections_title_color'} variable_state={"sections_title_color"} />
                      </li>
                      <li className="flex justify-between w-full">
                          <span className="flex ml-3 text-left text-md whitespace-nowrap"> {t("text_color")} </span>
                          <ColorPicker variable={'--sections_text_color'} variable_state={"sections_text_color"}/>
                      </li>
                      <li className="flex justify-between w-full">
                          <span className="flex ml-3 text-left text-md whitespace-nowrap"> {t("icons_color")} </span>
                          <ColorPicker variable={'--sections_icons_color'} variable_state={"sections_icons_color"}/>
                      </li>
                      <li className="flex justify-between w-full">
                          <span className="flex ml-3 text-left text-md whitespace-nowrap"> {t("bg_color")} </span>
                          <ColorPicker variable={'--sections_bg_color'} variable_state={"sections_bg_color"}/>
                      </li>
                      <li className="flex justify-between w-full">
                          <span className="flex ml-3 text-left text-md whitespace-nowrap"> {t("border_color")} </span>
                          <ColorPicker variable={'--sections_border_color'} variable_state={"sections_border_color"}/>
                      </li>
                      <li className="flex justify-between w-full">
                          <span className="flex ml-3 text-left text-md whitespace-nowrap"> {t("hover_bg_color")} </span>
                          <ColorPicker variable={'--sections_hover_bg_color'} variable_state={"sections_hover_bg_color"}/>
                      </li>
                      <li className="flex justify-between w-full">
                          <span className="flex ml-3 text-left text-md whitespace-nowrap"> {t("hover_text_color")} </span>
                          <ColorPicker variable={'--sections_hover_text_color'} variable_state={"sections_hover_text_color"}/>
                      </li>
                  </ul>
              </div>
            </div>
            <div className={`sm:w-1/2 px-5 py-3 max-w-full`}>
              <p className={`${state.darkMode?'':'sections_title_color'} pb-1 text-xl font-bold tracking-tight dark:text-white`}> {t("cards")} </p>
              <div className={`${state.darkMode?'':'sections_text_color'} flex justify-between items-center p-2 text-base font-medium text-gray-900 rounded-lg dark:text-white group`}>
                  <ul className="flex flex-col w-full">
                      <li className="flex justify-between w-full">
                          <span className="flex ml-3 text-left text-md whitespace-nowrap"> {t("title_color")} </span>
                          <ColorPicker variable={'--card_title_color'} variable_state={"card_title_color"} />
                      </li>
                      <li className="flex justify-between w-full">
                          <span className="flex ml-3 text-left text-md whitespace-nowrap"> {t("text_color")} </span>
                          <ColorPicker variable={'--card_text_color'} variable_state={"card_text_color"}/>
                      </li>
                      <li className="flex justify-between w-full">
                          <span className="flex ml-3 text-left text-md whitespace-nowrap"> {t("icons_color")} </span>
                          <ColorPicker variable={'--card_icons_color'} variable_state={"card_icons_color"}/>
                      </li>
                      <li className="flex justify-between w-full">
                          <span className="flex ml-3 text-left text-md whitespace-nowrap"> {t("bg_color")} </span>
                          <ColorPicker variable={'--card_bg_color'} variable_state={"card_bg_color"}/>
                      </li>
                      <li className="flex justify-between w-full">
                          <span className="flex ml-3 text-left text-md whitespace-nowrap"> {t("border_color")} </span>
                          <ColorPicker variable={'--card_border_color'} variable_state={"card_border_color"}/>
                      </li>
                      <li className="flex justify-between w-full">
                          <span className="flex ml-3 text-left text-md whitespace-nowrap"> {t("hover_bg_color")} </span>
                          <ColorPicker variable={'--card_hover_bg_color'} variable_state={"card_hover_bg_color"}/>
                      </li>
                      <li className="flex justify-between w-full">
                          <span className="flex ml-3 text-left text-md whitespace-nowrap"> {t("hover_text_color")} </span>
                          <ColorPicker variable={'--card_hover_text_color'} variable_state={"card_hover_text_color"}/>
                      </li>
                  </ul>
              </div>
            </div>
            <div className={`sm:w-1/2 px-5 py-3 max-w-full`}>
              <p className={`${state.darkMode?'':'sections_title_color'} pb-1 text-xl font-bold tracking-tight dark:text-white`}> {t("footer")} </p>
              <div className={`${state.darkMode?'':'sections_text_color'} flex justify-between items-center p-2 text-base font-medium text-gray-900 rounded-lg dark:text-white group`}>
                  <ul className="flex flex-col w-full">
                      <li className="flex justify-between w-full">
                          <span className="flex ml-3 text-left text-md whitespace-nowrap"> {t("text_color")} </span>
                          <ColorPicker variable={'--footer_text_color'} variable_state={"footer_text_color"} />
                      </li>
                  </ul>
              </div>
            </div>
            <div className={`sm:w-1/2 px-5 py-3 max-w-full`}>
              <p className={`${state.darkMode?'':'sections_title_color'} pb-1 text-xl font-bold tracking-tight dark:text-white`}> {t("page")} </p>
              <div className={`${state.darkMode?'':'sections_text_color'} flex justify-between items-center p-2 text-base font-medium text-gray-900 rounded-lg dark:text-white group`}>
                  <ul className="flex flex-col w-full">
                      <li className="flex justify-between w-full">
                          <span className="flex ml-3 text-left text-md whitespace-nowrap"> {t("bg_color")} </span>
                          <ColorPicker variable={'--page_bg_color'} variable_state={"page_bg_color"} />
                      </li>
                  </ul>
              </div>
            </div>
        </section>
        <Footer className="align-bottom" />
      </main>
    </div>  )
}
