import React from 'react'
import { useState, useContext } from 'react'
import { AppContext } from '../../../context/AppContext'

export const AdministrationMessage = () => {
    const [welcomeMessage, setWelcomeMessage] = useState("")
    const [state] = useContext(AppContext);


  return (
    <section className={` mb-4 ${welcomeMessage} transition-transform ease-out duration-300`}>
    <div className={`${state.darkMode?'':'sections_bg_color sections_border_color sections_text_color'} rounded-lg border dark:border-gray-600 shadow sm:flex sm:items-center sm:justify-between px-4 sm:px-6 xl:px-8 py-3 dark:bg-gray-800 antialiased`}>
      <div>
        <p className="mb-4 pb-3 text-sm dark:text-gray-400 sm:mb-0">Administration Welcoming Message </p>
      </div>
      <div className={`${state.darkMode?'':'sections_icons_color sections_hover_bg_color'} flex rounded-lg justify-center items-center space-x-1`}>
        <div onClick={()=>(setWelcomeMessage("hidden"))} data-tooltip-target="tooltip-message" className={`inline-flex justify-center p-2 rounded-lg cursor-pointer dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600`}>
          <svg className="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m5 15 7-7 7 7"/>
          </svg>
        </div>
        <div id="tooltip-message" role="tooltip" className="inline-block absolute invisible z-10 py-2 px-3 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm opacity-0 transition-opacity duration-300 tooltip dark:bg-gray-700">
            Hide
        <div className="tooltip-arrow" data-popper-arrow></div>
        </div>
      </div>
    </div>
  </section>  )
}
