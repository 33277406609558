import { Swiper, SwiperSlide } from "swiper/react";
import { SliderCard } from "../..";
import { Autoplay } from "swiper/modules";
import { FreeMode } from 'swiper/modules';


// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "../../../index.css";



export const Slider = ({ oportunities, title, type }) => {

  return (
    <section className="flex flex-wrap justify-between mb-4">
      <div className="px-5 py-3 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 max-w-full">
        <h5 className="pb-3 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
          {title}
        </h5>
        <Swiper
          spaceBetween={10}
          slidesPerView={"3"}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
          }}
          loop={true}
          modules={[FreeMode, Autoplay]}
          className="mySwiper"
        >
          {oportunities
            .filter((oportunitie) => type.includes(oportunitie.type))
            .map((oportunitie) => (
              <SwiperSlide
                className="min-h-[385px] min-w-[375px] overflow-clip"
                key={oportunitie.oportunitieId}
              >
                <SliderCard oportunitie={oportunitie} />
              </SwiperSlide>
            ))}
        </Swiper>
      </div>
    </section>
  );
};
