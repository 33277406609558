import { Navbar, Sidebar, Footer } from "../../components";
import { AppContext } from "../../context/AppContext";
import { useContext, useEffect, useState } from "react";
import { getHotel, getHotelById } from "../../services";
import { toast } from 'react-toastify';
import { useParams } from "react-router-dom";

import { BarSchedule } from "./components/BarSchedule";
import { Menu } from "./components/Menu";

export const Bar = () => {

  const [state, dispatch] = useContext(AppContext);
  const {hotelId} = useParams()
  const {serviceId} = useParams()
  const [service, setService]= useState()

  useEffect(()=>{
    // getHotel(hotelId).then((result)=>{
    //   const data = result
    //   dispatch({type:"SET_SELECTED_HOTEL", payload:data})
    // }).catch(error=>{
    // toast.error("Unable to get hotel info!")
    // })
    getHotelById(hotelId).then((result)=>{
      const data = result
      dispatch({type:"SET_CURRENT_HOTEL", payload:data})
    }).catch(error=>{
      toast.error("Unable to get hotel info!")
    })

  },[]) // eslint-disable-line

  useEffect(()=>{
    const newService = state.selectedHotel.services.find(service => service.serviceId===parseInt(serviceId) )
    setService(newService)

  },[state.selectedHotel, serviceId])

  function show(){ // eslint-disable-line
    console.log(state.selectedHotel.services)
    console.log(serviceId)
    console.log(service.serviceId)

  }

  return (
    service && (
      <>
        <div className={`${ state.darkMode ? "" : "page_bg_color" } antialiased dark:bg-gray-900`} >
          <Navbar />
          <Sidebar />
          <div className="h-auto pt-[61px] md:ml-[255px]">
            <section className={`${ state.darkMode ? "" : "card_bg_color card_border_color" } mx-4 mt-4 mb-4 px-5 py-3 border rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 max-w-full`} >
              <h5 className={`${ state.darkMode ? "" : "card_title_color" } mb-3 text-2xl font-semibold tracking-tight dark:text-white`} > {state.currentHotel.name2} </h5>
              <div className="w-full">
                <div className="flex max-sm:flex-wrap w-full">
                  <div className="w-3/5 max-sm:w-full">
                    <img src={service.serviceImage} alt="service"></img>
                    <div className="">
                      <p className={`${ state.darkMode ? "" : "card_title_color" } mt-5 font-normal dark:text-white text-lg`} > <b>{service.serviceName}</b> </p>
                      <BarSchedule />
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <Menu service={service} />
            <Footer />
          </div>
        </div>
      </>
    )
  )
}
