import { useState, useContext, useEffect } from "react";
import { AppContext } from "../../../../context/AppContext";
import { useTranslation } from "react-i18next";

export const InstallButton = () => {

    const [deferredPrompt, setDeferredPrompt] = useState(null);
    const [showButton, setShowButton] = useState(false); // eslint-disable-line
     const [state] = useContext(AppContext);
     const [t] = useTranslation("global")

  useEffect(() => {
    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
      setShowButton(true);
      console.log('beforeinstallprompt event fired');
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);

  const handleInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((result) => {
        if (result.outcome === 'accepted') {
          console.log('Usuário aceitou o prompt de instalação');
        } else {
          console.log('Usuário rejeitou o prompt de instalação');
        }
        setDeferredPrompt(null);
        setShowButton(false);
      });
    }
  };

  return (
    <button id="divInstallApp" onClick={()=>handleInstallClick()} className={` ${state.darkMode?'':'sidebar_hover_text_color sidebar_hover_bg_color'} flex justify-end items-center p-2 text-base font-medium rounded-lg dark:text-white dark:hover:bg-gray-700 group`} >
        <svg className={`${state.darkMode?'':'sidebar_icons_color sidebar_hover_text_color'} w-6 h-6 dark:text-gray-400`} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill={`currentColor`} viewBox="0 0 24 24">
            <path fillRule="evenodd" d="M4.857 3A1.857 1.857 0 0 0 3 4.857v4.286C3 10.169 3.831 11 4.857 11h4.286A1.857 1.857 0 0 0 11 9.143V4.857A1.857 1.857 0 0 0 9.143 3H4.857Zm10 0A1.857 1.857 0 0 0 13 4.857v4.286c0 1.026.831 1.857 1.857 1.857h4.286A1.857 1.857 0 0 0 21 9.143V4.857A1.857 1.857 0 0 0 19.143 3h-4.286Zm-10 10A1.857 1.857 0 0 0 3 14.857v4.286C3 20.169 3.831 21 4.857 21h4.286A1.857 1.857 0 0 0 11 19.143v-4.286A1.857 1.857 0 0 0 9.143 13H4.857ZM18 14a1 1 0 1 0-2 0v2h-2a1 1 0 1 0 0 2h2v2a1 1 0 1 0 2 0v-2h2a1 1 0 1 0 0-2h-2v-2Z" clipRule="evenodd"/>
        </svg>
        <span className={`${state.darkMode?'':'sidebar_text_color sidebar_hover_text_color'} flex-1 ml-3 text-left whitespace-nowrap`}>{t("add_to_homescreen")}</span>
    </button>
  )
}
