import React, { createContext, useContext, useRef } from 'react';

const RefsContext = createContext();

export const RefsProvider = ({ children }) => {

  const sidebarRef = useRef(null);
  const searchSidebarRef = useRef(null);
  const toggleSidebarRef = useRef(null);
  const toggleSearchRef = useRef(null);

  const contextValue = {
    sidebarRef,
    searchSidebarRef,
    toggleSidebarRef,
    toggleSearchRef
  };

  return (
    <RefsContext.Provider value={contextValue}>
      {children}
    </RefsContext.Provider>
  );
};

export const useRefs = () => useContext(RefsContext);
