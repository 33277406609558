import { useTranslation } from "react-i18next";

/**
 * @module Address
 * @description Component for managing the address information of a guest.
 * This component includes input fields for country selection, address, city,
 * and postal code, along with validation based on required fields.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Object} props.guest - The guest object containing address data.
 * @param {function} props.handleGuestChange - Function to handle changes in guest data.
 * @param {Object} props.initialData - Initial data including the list of countries.
 * @param {Array<Object>} props.requiredFields - List of required fields for validation,
 *                                               where each object contains:
 *                                               - {string} Field - The field name.
 *                                               - {boolean} IsMandatory - Indicates if the field is required.
 *
 * @returns {JSX.Element} The rendered address form fields.
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @version 1.0.0
 *
 * @example
 * // Example usage of the Address component
 * <Address
 *   guest={guestData}
 *   handleGuestChange={handleGuestChange}
 *   initialData={initialData}
 *   requiredFields={requiredFields}
 * />
 */
export const Address = ({
  guest,
  handleGuestChange,
  initialData,
  requiredFields,
}) => {
  const [t] = useTranslation("global");

  /**
   * Handles the change event when a country is selected.
   *
   * @function handleCountryChange
   * @param {Event} e - The change event from the select element representing country selection.
   * @param {number} id - The ID of the guest whose country is being changed.
   * @returns {void} No return value.
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @version 1.0.0
   *
   * @example
   * // Example usage of handleCountryChange
   * handleCountryChange(event, guest.id);
   */
  const handleCountryChange = (e, id) => {
    const selectedCountry = JSON.parse(e.target.value);
    handleGuestChange(id, "CountryId", selectedCountry.Id);
    handleGuestChange(id, "CountryIso", selectedCountry.IsoCode);
  };

  // Determine whether each field is required based on the provided validation rules
  const isRequiredCountryId = requiredFields.some(
    (field) => field.Field === "CountryId" && field.IsMandatory
  );

  const isRequiredAddress1 = requiredFields.some(
    (field) => field.Field === "Address1" && field.IsMandatory
  );

  const isRequiredTown = requiredFields.some(
    (field) => field.Field === "Town" && field.IsMandatory
  );

  const isRequiredZipCode = requiredFields.some(
    (field) => field.Field === "ZipCode" && field.IsMandatory
  );

  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-3 max-sm:gap-y-0 gap-3">
        <div>
          <label htmlFor="country" className="pl-2 pr-2 rounded-xl inline-block translate-y-3.5 text-center justify-center translate-x-3 bg-white text-sm font-medium text-gray-700 mb-1" >
            {`${t(`Address.country`)}`}
            <span className="text-gray-400 ml-2">
              {isRequiredCountryId ? "" : `${t(`FormFields.optional`)}`}
            </span>
          </label>
          <div className="">
            <select name="CountryId" required={isRequiredCountryId} onChange={(e) => handleCountryChange(e, guest.id)} value={JSON.stringify({ Id: guest.data.CountryId || "", IsoCode: guest.data.CountryIso || "", })} id="country" className="w-full px-4 py-2 rounded-lg border border-gray-700 focus:ring-2 text-xl focus:border-blue-500 transition duration-150 ease-in-out appearance-none" >
              <option>Selecione</option>
              {initialData.CountryList.map((country) => (
                <option key={country.Id} value={JSON.stringify({ Id: country.Id, IsoCode: country.IsoCode, })} >
                  {country.TranslatedDescription}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div>
          <label htmlFor="address" className="pl-2 pr-2 rounded-xl inline-block translate-y-3.5 text-center justify-center translate-x-3 bg-white text-sm font-medium text-gray-700 mb-1" >
            {`${t(`Address.address`)}`}
            <span className="text-gray-400 ml-2">
              {isRequiredAddress1 ? "" : `${t(`FormFields.optional`)}`}
            </span>
          </label>
          <input name="Address1" required={isRequiredAddress1} onChange={(e) => handleGuestChange(guest.id, e.target.name, e.target.value) } value={guest.data.Address1 || ""} type="text" id="Address1" className="w-full px-4 py-2 rounded-lg border border-gray-700 focus:ring-2 text-xl focus:border-blue-500 transition duration-150 ease-in-out" />
        </div>
        <div>
          <label htmlFor="city" className="pl-2 pr-2 rounded-xl inline-block translate-y-3.5 text-center justify-center translate-x-3 bg-white text-sm font-medium text-gray-700 mb-1" >
            {`${t(`Address.city`)}`}
            <span className="text-gray-400 ml-2">
              {isRequiredTown ? "" : `${t(`FormFields.optional`)}`}
            </span>
          </label>
          <input name="Town" required={isRequiredTown} onChange={(e) => handleGuestChange(guest.id, e.target.name, e.target.value) } value={guest.data.Town || ""} type="text" id="Town" className="w-full px-4 py-2 rounded-lg border border-gray-700 focus:ring-2 text-xl focus:border-blue-500 transition duration-150 ease-in-out" />
        </div>
        <div>
          <label htmlFor="postalCode" className="pl-2 pr-2 rounded-xl inline-block translate-y-3.5 text-center justify-center translate-x-3 bg-white text-sm font-medium text-gray-700 mb-1" >
            {`${t(`Address.zipcode`)}`}
            <span className="text-gray-400 ml-2">
              {isRequiredZipCode ? "" : `${t(`FormFields.optional`)}`}
            </span>
          </label>
          <input name="ZipCode" required={isRequiredZipCode} onChange={(e) => handleGuestChange(guest.id, e.target.name, e.target.value) } value={guest.data.ZipCode || ""} type="text" id="ZipCode" className="w-full px-4 py-2 rounded-lg border border-gray-700 focus:ring-2 text-xl focus:border-blue-500 transition duration-150 ease-in-out" />
        </div>
      </div>
    </>
  );
};
