import { useTranslation } from "react-i18next";

/**
 * @module Gender
 * @description Component for selecting a guest's gender.
 * This component provides a dropdown menu for selecting
 * the gender of the guest, with options for Male, Female, and Other.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {function} props.handleGuestChange - Function to handle changes in guest data.
 * @param {Object} props.guest - The guest object containing gender data.
 * @param {Array<Object>} props.requiredFields - List of required fields for validation,
 *                                               where each object contains:
 *                                               - {string} Field - The field name.
 *                                               - {boolean} IsMandatory - Indicates if the field is required.
 *
 * @returns {JSX.Element} The rendered gender selection dropdown.
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @version 1.0.0
 *
 * @example
 * // Example usage of the Gender component
 * <Gender
 *   handleGuestChange={handleGuestChange}
 *   guest={guestData}
 *   requiredFields={requiredFields}
 * />
 */
export const Gender = ({handleGuestChange, guest, requiredFields}) => {
  const [t] = useTranslation("global");

    // Check if the Gender field is required based on the provided validation rules
  const isRequired = requiredFields.some(
  field => field.Field === "Gender" && field.IsMandatory);

  return (
    <div>
    <label htmlFor="genero" className="pl-2 pr-2 rounded-xl inline-block translate-y-3.5 text-center justify-center translate-x-3 bg-white text-sm font-medium text-gray-700 mb-1" >{`${t(`Gender.gender`)}`}<span className="text-gray-400 ml-2">{isRequired? "": `${t(`FormFields.optional`)}`}</span></label>
    <div className="">
      <select name="Gender" required={isRequired}  onChange={(e)=>handleGuestChange(guest.id,e.target.name,e.target.value)} value={guest.data.Gender || ""} id="genero" className="w-full px-4 py-2 rounded-lg border border-gray-700 focus:ring-2 text-xl focus:border-blue-500 transition duration-150 ease-in-out appearance-none" >
        <option value="" disabled>Seleccione</option>
        <option>{`${t(`Gender.female`)}`}</option>
        <option>{`${t(`Gender.male`)}`}</option>
        <option>{`${t(`Gender.other`)}`}</option>
      </select>
    </div>
  </div>  )
}
