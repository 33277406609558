import { useState, useEffect,useContext } from 'react'
import { AppContext } from '../../../context/AppContext'
import { useTranslation } from 'react-i18next'
import { useCookies } from 'react-cookie'

export const CookieConsent = ()=> {
  const [isVisible, setIsVisible] = useState(false)
  const [state] = useContext(AppContext);
  const [t] = useTranslation("global");
  const [cookies, setCookie] = useCookies(['cookieConsent']);



  useEffect(() => {
    const timer = setTimeout(() => {
      if (!cookies.cookieConsent) {
        setIsVisible(true)
      }
    }, 1000)

    return () => clearTimeout(timer)
  }, []) // eslint-disable-line

  const handleAccept = () => {
    setCookie('cookieConsent', true, {path:"/"})
    setIsVisible(false)
  }

  if (!isVisible) return null

  return (
    <div className="fixed inset-0 bg-gray-900 bg-opacity-50 z-50 flex items-end justify-center">
        <div className={` ${ state.darkMode ? "" : "sections_bg_color sections_border_color" }bg-gray-100 shadow-md border transition-transform duration-300 ease-in-out transform translate-y-0`}>
        <div className="container mx-auto px-4 py-6 flex flex-col sm:flex-row items-center justify-between md:space-x-10">
            <p className="text-gray-700 text-center sm:text-left mb-4 sm:mb-0"> {t("cookies")} </p>
            <div className="flex items-center">
                 <button onClick={handleAccept} className="bg-gray-200 hover:bg-gray-300 md:mr-6 text-primary-foreground hover:bg-primary/90 px-4 py-2 rounded-md transition-colors duration-200" > Accept </button>
            </div>
        </div>
        </div>
    </div>

  )
}