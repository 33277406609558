import { ChevronDown } from "lucide-react";
import { useTranslation } from "react-i18next";

/**
 * @module ArrivalTime
 * @description Component for managing the arrival time and reservation notes for guests.
 * This component allows users to input the expected arrival time and any additional reservation notes.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Array<Object>} props.guests - An array of guest objects, where each object contains guest data.
 * @param {function} props.setGuests - Function to update the guests' data.
 *
 * @returns {JSX.Element} The rendered arrival time input and reservation notes.
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @version 1.0.0
 *
 * @example
 * // Example usage of the ArrivalTime component
 * <ArrivalTime guests={guestsArray} setGuests={setGuestsFunction} />
 */
export const ArrivalTime = ({ guests, setGuests }) => {
  const [t] = useTranslation("global");

  /**
   * @function handleGuestChange
   * @description Handles changes to the guest data fields.
   * This function updates the specific field for a guest identified by their id,
   * and updates the guests state accordingly.
   *
   * @param {number} id - The unique identifier of the guest to be updated.
   * @param {string} fieldName - The name of the field to update.
   * @param {any} value - The new value for the field.
   * @returns {void}
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @version 1.0.0
   *
   * @example
   * handleGuestChange(0, 'ArrivalTime', '15:00');
   */
  const handleGuestChange = (id, fieldName, value) => {
    setGuests((prevGuests) =>
      prevGuests.map((guest) =>
        guest.id === id
          ? { ...guest, data: { ...guest.data, [fieldName]: value } }
          : guest
      )
    );
  };

  return (
    <>
      {guests && guests.length > 0 && (
        <div className="flex flex-col space-y-4 max-w-md mx-auto p-4">
          <div className="mb-4 flex justify-center">
            <div className="w-full max-w-xs">
              <label htmlFor="horaChegada" className="block text-sm font-medium text-gray-700 mb-1 text-center" >
              {`${t(`ArrivalTime.arrivaltime`)}`}
              </label>
              <div className="relative">
                <input name="ArrivalTime" onChange={(e) => handleGuestChange(0, e.target.name, e.target.value) } value={guests[0].data.ArrivalTime || ""} type="time" className="w-full border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 transition duration-150 ease-in-out text-center" placeholder={`${t(`ArrivalTime.selecthour`)}`} aria-describedby="horaChegadaHelp" style={{ colorScheme: "light", textAlign: "center" }} />
                <ChevronDown className="absolute right-3 top-1/2 transform -translate-y-1/2 pointer-events-none text-gray-400" size={20} />
              </div>
            </div>
          </div>
          <div>
            <input name="ReservationNotes" onChange={(e) => handleGuestChange(0, e.target.name, e.target.value) } value={guests[0].data.ReservationNotes || ""} type="text" id="comentarios" className="w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" placeholder={`${t(`ArrivalTime.additionalcomments`)}`} />
          </div>
        </div>
      )}
    </>
  );
};
