import { useTranslation } from "react-i18next";

/**
 * @module Nif
 * @description Component for handling the NIF (Tax Identification Number) input for a guest.
 * This component allows for the input of a guest's NIF, indicates whether it is a required field,
 * and provides validation feedback.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Object} props.guest - The guest object containing data such as NIF and nationality.
 * @param {Object} props.nifValues - The current NIF values for guests, indexed by guest ID.
 * @param {Object} props.nifErrors - The current error messages for each guest's NIF input, indexed by guest ID.
 * @param {function} props.setNifValues - Function to update the NIF values in state.
 * @param {function} props.setNifErrors - Function to update the NIF error messages in state.
 * @param {function} props.validateNifonBlur - Function to validate the NIF when the input loses focus.
 * @param {Array<Object>} props.requiredFields - List of required fields for validation,
 *                                               where each object contains:
 *                                               - {string} Field - The field name.
 *                                               - {boolean} IsMandatory - Indicates if the field is required.
 *
 * @returns {JSX.Element} The rendered NIF input field.
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @version 1.0.0
 *
 * @example
 * // Example usage of the Nif component
 * <Nif
 *   guest={guestData}
 *   nifValues={nifValues}
 *   nifErrors={nifErrors}
 *   setNifValues={setNifValues}
 *   setNifErrors={setNifErrors}
 *   validateNifonBlur={validateNifonBlur}
 *   requiredFields={requiredFields}
 * />
 */
export const Nif = ({guest, nifValues, nifErrors, setNifValues, setNifErrors, validateNifonBlur, requiredFields}) => {
  const [t] = useTranslation("global");

    // Check if the FiscalNumber field is required based on the provided validation rules
    const isRequired = requiredFields.some(
      field => field.Field === "FiscalNumber" && field.IsMandatory);

    /**
     * @description Handles changes in the NIF input field for each guest.
     * This function updates the NIF value in the state and clears any existing error message.
     *
     * @function handleNifChange
     * @param {Object} e - The event object from the input change.
     * @param {string} id - The ID of the guest being updated.
     */
    const handleNifChange = (e, id) => {
        const { value } = e.target;

        // Update the NIF value for the specific guest
        setNifValues((prevNif) => ({ ...prevNif, [id]: value }));
        // Clear the error message when the input is edited
        setNifErrors((prevErrors) => ({ ...prevErrors, [id]: '' }));
      };

  return (
    <div>
    <label htmlFor="nif" className="pl-2 pr-2 rounded-xl inline-block translate-y-3.5 text-center justify-center translate-x-3 bg-white text-sm font-medium text-gray-700 mb-1" >{`${t(`Nif.vatnumber`)}`}<span className="text-gray-400 ml-2">{isRequired? "": `${t(`FormFields.optional`)}`}</span></label>
    <input name="FiscalNumber" required={isRequired} onChange={(e) => handleNifChange(e, guest.id)} onBlur={() =>validateNifonBlur(nifValues[guest.id], guest.data.NationalityIso, guest.id)} value={nifValues[guest.id] || ""} type="text" id="nif" className="w-full px-4 py-2 rounded-lg border border-gray-700 focus:ring-2 text-xl focus:border-blue-500 transition duration-150 ease-in-out" />
    {nifErrors[guest.id] && <p className="text-red-500 text-sm mt-1">{nifErrors[guest.id]}</p>} {/* Exibe mensagem de erro */}
    </div>  )
}
