import Skeleton from "react-loading-skeleton";

export const HotelSkeletonCard = () => {
  return (
    <div className={`h-min-[260px] max-md:w-full md:w-[360px] md:h-[260px] px-6 py-4 border rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 mb-3 md:mr-3 cursor-pointer`} >
      <div className="flex justify-between">
        <div> <Skeleton width={250} height={20}  /> </div>
        <div className=""> <Skeleton width={30} height={30} /> </div>
      </div>
      <p className={` mb-2 mt-4 font-normal dark:text-gray-400`}> <Skeleton count={5} style={{ marginBottom: '15px' }}/> </p>

    </div>
  );
};
