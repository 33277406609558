import { AppContext } from "../../context/AppContext";
import { useContext, useEffect, useState } from "react";
import { Navbar, Footer, Sidebar } from "../../components";
import { useTranslation } from "react-i18next";
import { WellnessCard } from "./components/WellnessCard"
import { WellnessOrders } from "./components/WellnessOrders";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getHotel, getHotelById } from "../../services";

export const Wellness = () => {
  const [state, dispatch] = useContext(AppContext);
  const [t] = useTranslation("global")
  const {hotelId} = useParams()
  const [services, setServices] = useState([])

  // useEffect(()=>{
  //   getHotel(hotelId).then((result)=>{
  //     const data = result
  //     dispatch({type:"SET_SELECTED_HOTEL", payload:data})
  //   }).catch(error=>{
  //   toast.error("Unable to get hotel info!")
  // })
  // getHotelById(hotelId).then((result)=>{
  //   const data = result
  //   dispatch({type:"SET_CURRENT_HOTEL", payload:data})
  // }).catch(error=>{
  // toast.error("Unable to get hotel info!")
  // })

  // },[]) // eslint-disable-line

  useEffect(()=>{

    if (state.selectedHotel) {
      const filteredServices = state.selectedHotel.services.filter(
        (service) => service.serviceModule === "wellness")
      setServices(filteredServices);
    }
  },[state.selectedHotel])

  function show(){
    console.log(services)
  }

  return (
    <div className={`antialiased ${ state.darkMode ? "" : "page_bg_color" } dark:bg-gray-900`} >
      <Navbar />
      <Sidebar />
      <div className="h-auto pt-[61px] md:ml-[255px]">
        <div className="mx-4 pt-4">
          <section className="mb-4 ">
              <div className={`px-5 py-3 ${state.darkMode?'':'sections_title_color sections_bg_color sections_border_color'} border border-gray-300 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 max-w-full`}>
                <h5 onClick={show} className="pb-3 text-2xl font-bold tracking-tight dark:text-white">{t("wellness")}</h5>
                <div className="flex flex-wrap" >
                  {services.map((service)=>(
                  <WellnessCard key={service.serviceId} serviceName={t(service.serviceType)} serviceImage={service.serviceImage} serviceStatus={service.serviceStatus} hotelId={hotelId} serviceId={service.serviceId} />
                  ))}
                </div>
              </div>
            </section>
            <section className="mb-4 ">
              <div className={`px-5 py-3 ${state.darkMode?'':'sections_title_color sections_bg_color sections_border_color'} border border-gray-300 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 max-w-full`}>
                <h5 className="pb-3 text-2xl font-bold tracking-tight dark:text-white">{t("your_appointments")}</h5>
                <div className="flex flex-wrap md:space-x-4" >
                  <WellnessOrders />
                </div>
              </div>
            </section>
        </div>
        <Footer />
      </div>
    </div>
  );
};
