import { useTranslation } from "react-i18next";


/**
 * @module DocumentType
 * @description Component for selecting the type of document for a guest.
 * This component includes a dropdown menu for selecting a document type,
 * with validation based on required fields.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {function} props.handleGuestChange - Function to handle changes in guest data.
 * @param {Object} props.guest - The guest object containing document type data.
 * @param {Array<Object>} props.initialData - Initial data containing available document types,
 *                                            where each object includes:
 *                                            - {string} Id - The unique identifier for the document type.
 *                                            - {string} Description - The name/description of the document type.
 * @param {Array<Object>} props.requiredFields - List of required fields for validation,
 *                                               where each object contains:
 *                                               - {string} Field - The field name.
 *                                               - {boolean} IsMandatory - Indicates if the field is required.
 *
 * @returns {JSX.Element} The rendered document type selection dropdown.
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @version 1.0.0
 *
 * @example
 * // Example usage of the DocumentType component
 * <DocumentType
 *   handleGuestChange={handleGuestChange}
 *   guest={guestData}
 *   initialData={initialData}
 *   requiredFields={requiredFields}
 * />
 */
export const DocumentType = ({handleGuestChange, guest, initialData, requiredFields}) => {
  const [t] = useTranslation("global");

    // Determine if the document type field is required based on the provided validation rules
    const isRequired = requiredFields.some(
      field => field.Field === "DocType" && field.IsMandatory);

  return (
    <div>
    <label htmlFor="tipoDocumento" className="pl-2 pr-2 rounded-xl inline-block translate-y-3.5 text-center justify-center translate-x-3 bg-white text-sm font-medium text-gray-700 mb-1" >{`${t(`DocumentType.documenttype`)}`}<span className="text-gray-400 ml-2">{isRequired? "": `${t(`FormFields.optional`)}`}</span></label>
    <div className="">
      <select name="DocType" required={isRequired}  onChange={(e)=>handleGuestChange(guest.id,e.target.name,e.target.value)} value={guest.data.DocType || ""} id="tipoDocumento" className="w-full px-4 py-2 text-xl rounded-lg border border-gray-700 focus:ring-2 focus:border-blue-500 transition duration-150 ease-in-out appearance-none" >
        <option value="" disabled>Seleccione</option>
        {initialData.GuestDocumentTypes.map((document)=>(<option key={document.Id} value={document.Description}>{document.Description}</option>))}
      </select>
    </div>
  </div>  )
}
