import {Link} from 'react-router-dom'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { useContext } from 'react'
import { AppContext } from '../../../context/AppContext'
import { NotebookPen } from 'lucide-react'
import { useCookies } from 'react-cookie'

export const HotelReservationsCard = ({reservation, hotel}) => {

    const [t] = useTranslation("global")
    const [state] = useContext(AppContext);
    const [cookie, setCookie] = useCookies(['platformUid']); // eslint-disable-line

  return ((reservation &&
        <div to={`/hotel/${hotel.pmsId.substring(0, 8)}/reservation/${reservation.detailid}`} state={reservation} className={`${state.darkMode?'':'card_icons_color card_border_color card_bg_color card_hover_bg_color'} h-min-[260px] max-md:w-full md:w-[360px] px-6 py-4 border rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 mb-3 md:mr-3 cursor-pointer`}>
            <div className='flex justify-between'>
                <div>
                    <h5 className={`${state.darkMode?'':'card_icons_color'} mb-2 text-2xl font-semibold tracking-tight text-gray-900 dark:text-white`}>{hotel.name2}</h5>
                </div>
                <Link to={`/hotel/${hotel.pmsId.substring(0, 8)}/reservation/${reservation.detailid}`} className=''>
                    <svg className="w-6 h-6 ms-2.5 rtl:rotate-[270deg]" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 11v4.833A1.166 1.166 0 0 1 13.833 17H2.167A1.167 1.167 0 0 1 1 15.833V4.167A1.166 1.166 0 0 1 2.167 3h4.618m4.447-2H17v5.768M9.111 8.889l7.778-7.778"/>
                    </svg>
                </Link>
            </div>
            <div className='flex items-center'>
                <span className={`${state.darkMode?'':'scard_text_color'} mb-2 mr-2 font-normal dark:text-gray-400`}>{dayjs(reservation.checkin).format(t("dayjs_format"))}</span>
                <span className={`${state.darkMode?'':'card_text_color'} flex mb-2 mr-2 align-middle font-normal dark:text-gray-400`}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right align-middle" viewBox="0 0 16 16"> <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"/> </svg></span>
                <span className={`${state.darkMode?'':'card_text_color'} mb-2 font-normal dark:text-gray-400`}>{dayjs(reservation.checkout).format(t("dayjs_format")) }</span>
            </div>
            <p className={`${state.darkMode?'':'card_title_color'} mb-2 font-normal dark:text-gray-400`}><b>{`${t("reservation_number")}:`} </b>{reservation.resno}</p>
            <p className={`${state.darkMode?'':'card_title_color'} mb-2 font-normal dark:text-gray-400`}><b>{`${t("status")}:`} </b>{reservation.Status}</p>
            <div className='flex justify-between'>
                <div>
                    <p className={`${state.darkMode?'':'card_title_color'} mb-2 font-normal dark:text-gray-400`}><b>{`${t("room")}:`} </b>{reservation.room}</p>
                    <p className={`${state.darkMode?'':'card_title_color'} mb-2 font-normal dark:text-gray-400`}><b>{`${t("balance")}:`} </b>{reservation.Balance}€</p>
                </div>
                <div className=''>
                    <Link to={`/hotel/${cookie.platformUid}/onlinecheckin/${reservation.detailid}/${reservation.resno}`} className={`${state.darkMode?'':'card_text_color card_border_color card_hover_bg_color'} flex border rounded-md shadow p-2`}>
                        <p className='mr-2'>Online CheckIn</p>
                        <NotebookPen />
                    </Link>
                </div>
            </div>

        </div>
)
  )}
