import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { useContext } from "react";
import { AppContext } from "../../../context/AppContext";

export const WellnessOrdersCard = ({ id, user_id, hotel_id, service_id, type, date, status, }) => {
  const [t] = useTranslation("global");
  const [state] = useContext(AppContext);

  return (
    <Link to={``}>
      <div className={`flex justify-between py-4 px-2 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700`} >
        <div className="flex flex-col justify-center mr-2">
            { type==="room_service" &&
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-bell" viewBox="0 0 16 16">
                <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2M8 1.918l-.797.161A4 4 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4 4 0 0 0-3.203-3.92zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5 5 0 0 1 13 6c0 .88.32 4.2 1.22 6"/>
            </svg>}
            { type==="table_reservation" &&
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-clipboard2-check" viewBox="0 0 16 16">
                <path d="M9.5 0a.5.5 0 0 1 .5.5.5.5 0 0 0 .5.5.5.5 0 0 1 .5.5V2a.5.5 0 0 1-.5.5h-5A.5.5 0 0 1 5 2v-.5a.5.5 0 0 1 .5-.5.5.5 0 0 0 .5-.5.5.5 0 0 1 .5-.5z"/>
                <path d="M3 2.5a.5.5 0 0 1 .5-.5H4a.5.5 0 0 0 0-1h-.5A1.5 1.5 0 0 0 2 2.5v12A1.5 1.5 0 0 0 3.5 16h9a1.5 1.5 0 0 0 1.5-1.5v-12A1.5 1.5 0 0 0 12.5 1H12a.5.5 0 0 0 0 1h.5a.5.5 0 0 1 .5.5v12a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5z"/>
                <path d="M10.854 7.854a.5.5 0 0 0-.708-.708L7.5 9.793 6.354 8.646a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0z"/>
            </svg>}
        </div>
        <div className="flex-1">
            <div className="flex flex-row items-center" >
                <h5 className={`${state.darkMode?'':'card_border_color card_title_color'} text-xl mr-4 font-bold tracking-tight max-sm:text-sm dark:text-white`}> {type === "spa" && t("spa")}{type === "sauna" && t("sauna")}{type === "turkish_bath" && t("turkish_bath")}{type === "gym" && t("gym")}{type === "tennis_court" && t("tennis_court")}</h5>
                <div className="flex flex-wrap justify-center max-sm:text-sm">
                    <label className={`w-[10px] h-[10px] ${status==="active" && "bg-green-500 dark:bg-white"} ${status==="completed" && "bg-gray-500 dark:bg-white"} rounded-full my-auto mr-2`}></label>
                    <label className={`${status==="active" && "text-green-500 dark:bg-white"} ${status==="completed" && "text-gray-900 dark:bg-white"}`}>{status}</label>
                </div>
            </div>
          <p className="font-normal text-gray-700 dark:text-gray-400"> {" "} {dayjs(date).format(t("dayjs_format"))}{" "} </p>
        </div>
        <div className="flex flex-col justify-center">
          <svg className="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" >
            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m9 5 7 7-7 7" />
          </svg>
        </div>
      </div>
    </Link>
  );
};
