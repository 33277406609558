import { ReservationsCard } from "./ReservationsCard";
import { getHostReservation } from "../../../services";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { useCallback} from "react";
import { SkeletonCard } from "./SkeletonCard";
import { useCookies } from "react-cookie";

export const ReservationsSection = ({ reservations, groupHotels, setReservations}) => {

    const [loadingReservation, setLoadingReservation]= useState(true)
    const [cookies] = useCookies(['platformUid', 'sUid', 'profileKey']);

    const fetchReservations = useCallback(async () => {
      if (groupHotels && groupHotels.length > 0) {
        try {
          setLoadingReservation(true)
          const reservationPromises = groupHotels.map((hotel) =>
            getHostReservation(cookies.sUid, cookies.profileKey, hotel)
          );
          const results = await Promise.all(reservationPromises);
          const combinedReservations = results.flat();
          setReservations(combinedReservations);
        } catch (error) {
          toast.error("Unable to get reservations!");
        } finally{
          setLoadingReservation(false)
        }
      }
    }, [groupHotels, cookies.sUid, setReservations, cookies.profileKey]);

    useEffect(() => {
      fetchReservations();
    }, [fetchReservations]);

  return (
    <>
     {loadingReservation ? (
                <SkeletonCard />
            ) : (
      !loadingReservation && reservations &&
      (reservations.length && (
        <div className="flex flex-wrap"> {reservations.map((reservation, index) => ( <ReservationsCard key={index} reservation={reservation} /> ))} </div>
      ))
      )}
    </>
  );
};
