import { useState } from "react";
import { useTranslation } from "react-i18next";

/**
 * @module Phone
 * @description Component for handling a guest's phone number input, including country dial code selection.
 * This component provides a dropdown for selecting a dial code, a text input for the phone number,
 * and validates whether the phone number input is required based on provided rules.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Object} props.initialData - The initial data containing country information and dial codes.
 * @param {Object} props.guest - The guest object containing data such as the phone number.
 * @param {function} props.handleGuestChange - Function to update guest data.
 * @param {Array<Object>} props.requiredFields - List of required fields for validation,
 *                                               where each object contains:
 *                                               - {string} Field - The field name.
 *                                               - {boolean} IsMandatory - Indicates if the field is required.
 *
 * @returns {JSX.Element} The rendered phone number input field with dial code selection.
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @version 1.0.0
 *
 * @example
 * // Example usage of the Phone component
 * <Phone
 *   initialData={initialData}
 *   guest={guestData}
 *   handleGuestChange={handleGuestChange}
 *   requiredFields={requiredFields}
 * />
 */
export const Phone = ({ initialData, guest, handleGuestChange, requiredFields, }) => {
  const [selectedDialCode, setSelectedDialCode] = useState(""); // State to store the selected dial code
  const [phoneNumber, setPhoneNumber] = useState(guest.data.Phone || ""); // State to store the phone number
  const [isDialCodeSelected, setIsDialCodeSelected] = useState(false); // State to check if a dial code has been selected
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // State to check if the dropdown is open
  const [t] = useTranslation("global");

  // Check if the Phone field is required based on the provided validation rules
  const isRequired = requiredFields.some(
    (field) => field.Field === "Phone" && field.IsMandatory
  );

  /**
   * @function handleDialCodeChange
   * @description Handles changes to the dial code selection.
   * This function is triggered when the user selects a new dial code from the dropdown.
   * It updates the local state with the new dial code and formats the phone number
   * to include the selected dial code, ensuring the previous dial code is removed
   * from the phone number if it exists.
   *
   * @param {Event} e - The event object from the change event of the select element.
   * @returns {void}
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @version 1.0.0
   *
   * @example
   * // When a user selects a new dial code from the dropdown:
   * handleDialCodeChange(event);
   *
   * // This will result in updating the guest's phone number to include the new dial code,
   * // formatted as "dialCode phoneNumber", without any previous dial code.
   *
   * @see handleGuestChange
   */
  const handleDialCodeChange = (e) => {
    const dialCode = e.target.value; // Get the selected dial code
    setSelectedDialCode(dialCode); // Update the dial code in state
    setIsDialCodeSelected(true); // Mark that the dial code has been selected

    // Update the guest's phone number with the new format, if a phone number exists
    if (phoneNumber) {
      handleGuestChange(
        guest.id,
        "Phone",
        `${dialCode} ${phoneNumber.replace(/^\+\d+\s/, "")}`
      ); // Remove o código anterior
    }
  };

  /**
   * @function handlePhoneChange
   * @description Updates the phone number based on user input.
   * This function is triggered when the user types in the phone number input field.
   * It captures the current input value, updates the local state with the new phone number,
   * and formats the phone number by prepending the selected dial code before saving it
   * to the guest's data.
   *
   * @param {Event} e - The event object from the change event of the input element.
   * @returns {void}
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @version 1.0.0
   *
   * @example
   * // When a user types a new phone number:
   * handlePhoneChange(event);
   *
   * // This will result in updating the guest's phone number to be formatted as
   * // "selectedDialCode phoneNumber", where selectedDialCode is the currently selected
   * // dial code from the dropdown.
   *
   * @see handleGuestChange
   */
  const handlePhoneChange = (e) => {
    const value = e.target.value; // Get the value from the phone input
    // Get the value from the phone input
    setPhoneNumber(value);
    // Call the function to update the guest with the formatted phone number
    handleGuestChange(guest.id, "Phone", `${selectedDialCode} ${value}`); // Pass formatted value to the function
  };

  return (
    <div>
      <div className="flex w-full">
        <div className="flex w-1/3 items-end">
          <select onChange={(e)=>{handleDialCodeChange(e);setIsDropdownOpen(false)}} required={isRequired} name="DialCode" className="w-full px-4 py-2 rounded-l-lg border border-gray-700 focus:ring-2 text-xl focus:border-blue-500 transition duration-150 ease-in-out appearance-none" value={isDialCodeSelected ? selectedDialCode : ""} onSelect={() => setIsDropdownOpen(false)} onClick={() => setIsDropdownOpen(!isDropdownOpen)} >
            <option value="">Seleccione</option>
            {initialData.CountryList.map((country) => {
              const dialCode = initialData.DialCodes[country.IsoCode] || ""; // Get the dial code by IsoCode
              return (
                dialCode && ( // Render only if there is a valid dial code
                  <option key={country.Id} value={dialCode}>
                    {isDropdownOpen
                      ? `${country.TranslatedDescription} ${dialCode}`
                      : dialCode}
                  </option>
                )
              );
            })}
          </select>
        </div>
        <div className="w-full">
          <label htmlFor="telefone" className="pl-2 pr-2 rounded-xl inline-block translate-y-3.5 translate-x-3 text-center justify-center bg-white text-sm font-medium text-gray-700 mb-1" >{`${t(`Phone.phonenumber`)}`}<span className="text-gray-400 ml-2">{isRequired? "": `${t(`FormFields.optional`)}`}</span></label>
          <input name="Phone" onChange={handlePhoneChange} value={phoneNumber} type="tel" id="telefone" className="w-full px-4 py-2 rounded-r-lg border border-gray-700 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-xl transition duration-150 ease-in-out" />
        </div>
      </div>
    </div>
  );
};
