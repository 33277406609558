import { Routes, Route } from 'react-router-dom'
import { Overview, PageNotFound, Reserva, HotelOverview, Settings, RestauranteAndBares, Wellness, Restaurant, Bar, WellnessDetail, Theme, OnlineCheckIn} from '../pages'
import { AppContext } from '../context/AppContext'
import { ProtectedRoute } from './ProtectedRoute'
import { useEffect, useState,useContext } from "react";



/**
 * Component that sets up routes for navigation
 * @returns Routing for navigation
 */
export const AllRoutes = () => {

  const [state] = useContext(AppContext);
  const [darkMode]=useState(JSON.parse(localStorage.getItem(`darkMode`)) || false)

  /**
   * Function that set dinamically the theme each time we enter a hotel enviroment. If no hotel is selected, it sets the "Host theme".
   */
    function atualizarTema() {
      var root = document.querySelector(':root');

      if (state.selectedHotel && state.selectedHotel.id) {
        root.style.setProperty('--border1', state.selectedHotel.theme.border1);
        root.style.setProperty('--bg1', state.selectedHotel.theme.bg1);
        root.style.setProperty('--bg2', state.selectedHotel.theme.bg2);
        root.style.setProperty('--txt1', state.selectedHotel.theme.txt1);
        root.style.setProperty('--txt2', state.selectedHotel.theme.txt2);
        root.style.setProperty('--navbar_bg', state.selectedHotel.theme.navbar_bg);
        root.style.setProperty('--sidebar_bg', state.selectedHotel.theme.sidebar_bg);
        root.style.setProperty('--sidebar_txt1', state.selectedHotel.theme.sidebar_txt1);
        root.style.setProperty('--sidebar_txt2', state.selectedHotel.theme.sidebar_txt2);

        root.style.setProperty('--sidebar_title_color', state.selectedHotel.theme.sidebar_title_color);
        root.style.setProperty('--sidebar_text_color', state.selectedHotel.theme.sidebar_text_color);
        root.style.setProperty('--sidebar_icons_color', state.selectedHotel.theme.sidebar_icons_color);
        root.style.setProperty('--sidebar_bg_color', state.selectedHotel.theme.sidebar_bg_color);
        root.style.setProperty('--sidebar_border_color', state.selectedHotel.theme.sidebar_border_color);
        root.style.setProperty('--sidebar_hover_bg_color', state.selectedHotel.theme.sidebar_hover_bg_color);
        root.style.setProperty('--sidebar_hover_text_color', state.selectedHotel.theme.sidebar_hover_text_color);

        root.style.setProperty('--navbar_icons_color', state.selectedHotel.theme.navbar_icons_color);
        root.style.setProperty('--navbar_bg_color', state.selectedHotel.theme.navbar_bg_color);
        root.style.setProperty('--navbar_border_color', state.selectedHotel.theme.navbar_border_color);
        root.style.setProperty('--navbar_hover_bg_color', state.selectedHotel.theme.navbar_hover_bg_color);
        root.style.setProperty('--navbar_hover_text_color', state.selectedHotel.theme.navbar_hover_text_color);

        root.style.setProperty('--page_bg_color', state.selectedHotel.theme.page_bg_color);

        root.style.setProperty('--sections_title_color', state.selectedHotel.theme.sections_title_color);
        root.style.setProperty('--sections_text_color', state.selectedHotel.theme.sections_text_color);
        root.style.setProperty('--sections_icons_color', state.selectedHotel.theme.sections_icons_color);
        root.style.setProperty('--sections_bg_color', state.selectedHotel.theme.sections_bg_color);
        root.style.setProperty('--sections_border_color', state.selectedHotel.theme.sections_border_color);
        root.style.setProperty('--sections_hover_bg_color', state.selectedHotel.theme.sections_hover_bg_color);
        root.style.setProperty('--sections_hover_text_color', state.selectedHotel.theme.sections_hover_text_color);

        root.style.setProperty('--card_title_color', state.selectedHotel.theme.card_title_color);
        root.style.setProperty('--card_text_color', state.selectedHotel.theme.card_text_color);
        root.style.setProperty('--card_icons_color', state.selectedHotel.theme.card_icons_color);
        root.style.setProperty('--card_bg_color', state.selectedHotel.theme.card_bg_color);
        root.style.setProperty('--card_border_color', state.selectedHotel.theme.card_border_color);
        root.style.setProperty('--card_hover_bg_color', state.selectedHotel.theme.card_hover_bg_color);
        root.style.setProperty('--card_hover_text_color', state.selectedHotel.theme.card_hover_text_color);

        root.style.setProperty('--footer_text_color', state.selectedHotel.theme.footer_text_color);

    } else {
        root.style.setProperty('--sidebar_title_color', state.defaultTheme.sidebar_title_color);
        root.style.setProperty('--sidebar_text_color', state.defaultTheme.sidebar_text_color);
        root.style.setProperty('--sidebar_icons_color', state.defaultTheme.sidebar_icons_color);
        root.style.setProperty('--sidebar_bg_color', state.defaultTheme.sidebar_bg_color);
        root.style.setProperty('--sidebar_border_color', state.defaultTheme.sidebar_border_color);
        root.style.setProperty('--sidebar_hover_bg_color', state.defaultTheme.sidebar_hover_bg_color);
        root.style.setProperty('--sidebar_hover_text_color', state.defaultTheme.sidebar_hover_text_color);

        root.style.setProperty('--navbar_icons_color', state.defaultTheme.navbar_icons_color);
        root.style.setProperty('--navbar_bg_color', state.defaultTheme.navbar_bg_color);
        root.style.setProperty('--navbar_border_color', state.defaultTheme.navbar_border_color);
        root.style.setProperty('--navbar_hover_bg_color', state.defaultTheme.navbar_hover_bg_color);
        root.style.setProperty('--navbar_hover_text_color', state.defaultTheme.navbar_hover_text_color);

        root.style.setProperty('--page_bg_color', state.defaultTheme.page_bg_color);

        root.style.setProperty('--sections_title_color', state.defaultTheme.sections_title_color);
        root.style.setProperty('--sections_text_color', state.defaultTheme.sections_text_color);
        root.style.setProperty('--sections_icons_color', state.defaultTheme.sections_icons_color);
        root.style.setProperty('--sections_bg_color', state.defaultTheme.sections_bg_color);
        root.style.setProperty('--sections_border_color', state.defaultTheme.sections_border_color);
        root.style.setProperty('--sections_hover_bg_color', state.defaultTheme.sections_hover_bg_color);
        root.style.setProperty('--sections_hover_text_color', state.defaultTheme.sections_hover_text_color);

        root.style.setProperty('--card_title_color', state.defaultTheme.card_title_color);
        root.style.setProperty('--card_text_color', state.defaultTheme.card_text_color);
        root.style.setProperty('--card_icons_color', state.defaultTheme.card_icons_color);
        root.style.setProperty('--card_bg_color', state.defaultTheme.card_bg_color);
        root.style.setProperty('--card_border_color', state.defaultTheme.card_border_color);
        root.style.setProperty('--card_hover_bg_color', state.defaultTheme.card_hover_bg_color);
        root.style.setProperty('--card_hover_text_color', state.defaultTheme.card_hover_text_color);

        root.style.setProperty('--footer_text_color', state.defaultTheme.footer_text_color);
    }
  }

  atualizarTema()

  /**
   * UseEffect to update the darkMode theme state in the App.
   */
  useEffect(()=>{
    localStorage.setItem(`darkMode`, JSON.stringify(darkMode));
    if(darkMode){
      document.documentElement.classList.add(`dark`)
    } else {
      document.documentElement.classList.remove(`dark`)
    }
  },[]) // eslint-disable-line

  return (
    <>
        <Routes>
            <Route path="/overview/:platformUid/:sUid" element={<Overview />} />

            <Route path="/hotel/:platformUid" element={<HotelOverview />} />
            <Route path="/hotel/:platformUid/reservation/:detailId" element={<Reserva />} />

            <Route path="/hotel/:platformUid/onlinecheckin/:detailId/:resNo" element={<OnlineCheckIn />} />

            <Route path="/hotel/:hotelId/restaurantandbars" element={<RestauranteAndBares />} end />
            <Route path="/hotel/:hotelId/restaurant/:serviceId" element={<Restaurant />}/>
            <Route path="/hotel/:hotelId/bar/:serviceId" element={<Bar />} />

            <Route path="/hotel/:hotelId/wellness" element={<ProtectedRoute><Wellness /></ProtectedRoute>} end />
            <Route path="/hotel/:hotelId/wellness/:serviceId" element={<ProtectedRoute><WellnessDetail /></ProtectedRoute>} />

            <Route path="/settings" element={<Settings />} end />
            <Route path="/theme" element={<Theme />} end />

            <Route path="*" element={<PageNotFound />} />

        </Routes>
    </>
  )
}
