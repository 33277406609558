/* import Api from "../data/api.js"; */
const Reducer = (state, action) => {

    switch (action.type) {

      case "UPDATE_DARKMODE":
        return {
          ...state,
          darkMode: action.payload,
        };

      case "TOGGLE_MOBILE_SIDEBAR":
        return {
          ...state,
          hiddenMobileSidebar: action.payload,
        };

      case "SET_USER_DETAILS":
        return {
          ...state,
          userDetails: action.payload,
        };

       case "SET_CURRENT_HOTEL":
        return {
          ...state,
          currentHotel: {
            id: action.payload.id,
            hotelId: action.payload.hotelId,
            name1: action.payload.name1,
            name2: action.payload.name2,
            contact: action.payload.contact,
            address1: action.payload.address1,
            address2: action.payload.address2,
            zipCode: action.payload.zipCode,
            city: action.payload.city,
            countryCode: action.payload.countryCode,
            countryDescription: action.payload.countryDescription,
            fiscalNumber: action.payload.fiscalNumber,
            phone: action.payload.phone,
            email: action.payload.email,
            webSite: action.payload.webSite,
            defaultCurrencyCode: action.payload.defaultCurrencyCode,
            defaultCultureCode: action.payload.defaultCultureCode,
            defaultTimeZone: action.payload.defaultTimeZone,
            hotelDate: action.payload.hotelDate,
            licenseCode: action.payload.licenseCode,
            licenseCode2: action.payload.licenseCode2,
            licenseValidUntil: action.payload.licenseValidUntil,
            licenseFlag1: action.payload.licenseFlag1,
            licenseFlag2: action.payload.licenseFlag2,
            licenseFeatures: action.payload.licenseFeatures,
            licensedRooms: action.payload.licensedRooms,
            licensedPos: action.payload.licensedPos,
            licensePosLocation: action.payload.licensePosLocation,
            sortOrder: action.payload.sortOrder,
            deleted: action.payload.deleted,
            pmsTimestamp: action.payload.pmsTimestamp,
            pmsId: action.payload.pmsId,
            hotelGroupId: action.payload.hotelGroupId,
            pmsType: action.payload.pmsType,
            currentVersion: action.payload.currentVersion,
            lastServicePack_File_Ordinal: action.payload.lastServicePack_File_Ordinal,
            lastServicePack_File_Timestamp: action.payload.lastServicePack_File_Timestamp,
            lastServicePack_Sql_Ordinal: action.payload.lastServicePack_Sql_Ordinal,
            lastServicePack_Sql_Timestamp: action.payload.lastServicePack_Sql_Timestamp,
            apiEndPoint: action.payload.apiEndPoint,
            updatedOn: action.payload.updatedOn,
            hotfixInfo: action.payload.hotfixInfo,
            subscriptionKey: action.payload.subscriptionKey,
            groupId: action.payload.groupId
       }}

       case "SET_DEFAULT_THEME":
        console.log("Reducer payload:", action.payload)
        return {
          ...state,
          defaultTheme: {
            ...state.defaultTheme,  // Mantém as cores já existentes
            ...action.payload       // Sobrescreve apenas as cores do payload
       }}

      default:
        return state;
    }
  };

  export default Reducer;

