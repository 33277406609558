
import { useTranslation } from "react-i18next";

/**
 * @module DocumentNumber
 * @description Component for managing the document number of a guest.
 * This component includes an input field for entering the document number,
 * with validation based on required fields.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {function} props.handleGuestChange - Function to handle changes in guest data.
 * @param {Object} props.guest - The guest object containing document number data.
 * @param {Array<Object>} props.requiredFields - List of required fields for validation,
 *                                               where each object contains:
 *                                               - {string} Field - The field name.
 *                                               - {boolean} IsMandatory - Indicates if the field is required.
 *
 * @returns {JSX.Element} The rendered document number input field.
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @version 1.0.0
 *
 * @example
 * // Example usage of the DocumentNumber component
 * <DocumentNumber
 *   handleGuestChange={handleGuestChange}
 *   guest={guestData}
 *   requiredFields={requiredFields}
 * />
 */
export const DocumentNumber = ({handleGuestChange, guest, requiredFields}) => {
  const [t] = useTranslation("global");

    // Determine if the document number field is required based on the provided validation rules
    const isRequired = requiredFields.some(
      field => field.Field === "DocNumber" && field.IsMandatory);

  return (
    <div>
    <label htmlFor="numeroDocumento" className="pl-2 pr-2 rounded-xl inline-block translate-y-3.5 text-center justify-center translate-x-3 bg-white text-sm font-medium text-gray-700 mb-1" >{`${t(`DocumentNumber.documentnumber`)}`}<span className="text-gray-400 ml-2">{isRequired? "": `${t(`FormFields.optional`)}`}</span></label>
    <input name="DocNumber"required={isRequired}  onChange={(e)=>handleGuestChange(guest.id,e.target.name,e.target.value)} value={guest.data.DocNumber || ""}  type="text" id="numeroDocumento" className="w-full px-4 py-2 rounded-lg border border-gray-700 focus:ring-2 text-xl focus:border-blue-500 transition duration-150 ease-in-out" />
  </div>  )
}
