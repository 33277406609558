/**
 * Function that send a request to API to get host reservation in a hotel
 * @param {string} detailId reservation detailId
 * @param {object} hotel Hotel that has a subscriptionKey to send requests to Azure
 * @returns host reservation info.
 */
export async function getReservationByDetailId(detailId, hotel){
    const requestOptions = {
        method:"GET",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers:{"Content-Type":"application/json",
                 "Ocp-Apim-Subscription-Key": hotel.subscriptionKey,}
    }

    const response = await fetch(`${process.env.REACT_APP_BACKEND_IP}/gxplocal/bridge/Gxp/Reservation/Search?data.detailId=${detailId}`, requestOptions)

    if(!response.ok){
        throw {message: response.statusText, status: response.status}//eslint-disable-line
    }
    const data = await response.json()
    return data
}

/**
 * Function that send a request to Online Checkin API to get host reservation in a hotel
 * @param {string} detailId reservation detailId
 * @param {object} hotel Hotel that has a subscriptionKey to send requests to Azure
 * @returns host reservation info from online checkin endpoint
 */
export async function searchReservationGuests(detailId, hotel, cultureCode){
    const requestOptions = {
        method:"GET",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers:{"Content-Type":"application/json",
                 "Ocp-Apim-Subscription-Key": hotel.subscriptionKey,}
    }
    const response = await fetch(`${process.env.REACT_APP_BACKEND_IP}/gxplocal/SearchReservationGuests?filter.reservationDetailId=${detailId}&filter.cultureCode=${cultureCode}`, requestOptions)

    if(!response.ok){
        throw {message: response.statusText, status: response.status}//eslint-disable-line
    }
    const data = await response.json()
    return data
}

export async function searchReservation(resNo, detailId, hotel, GUid, cultureCode){
    const requestOptions = {
        method:"GET",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers:{"Content-Type":"application/json",
                 "Ocp-Apim-Subscription-Key": hotel.subscriptionKey,}
    }
    const response = await fetch(`${process.env.REACT_APP_BACKEND_IP}/gxplocal/Onlinecheckin/SearchReservation?filter.reservationNr=${resNo}&filter.reservationDetailId=${detailId}&filter.guestUid=${GUid}&filter.cultureCode=${cultureCode}`, requestOptions)

    if(!response.ok){
        throw {message: response.statusText, status: response.status}//eslint-disable-line
    }
    const data = await response.json()
    return data
}

export async function saveReservationGuests( hotel, guests){
    const requestOptions = {
        method:"POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers:{"Content-Type":"application/json",
                 "Ocp-Apim-Subscription-Key": hotel.subscriptionKey,},
        body: JSON.stringify(guests)
    }
    const response = await fetch(`${process.env.REACT_APP_BACKEND_IP}/gxplocal/Onlinecheckin/SaveReservationGuests`, requestOptions)

    if(!response.ok){
        throw {message: response.statusText, status: response.status}//eslint-disable-line
    }
    const data = await response.json()
    return data
}