import { useTranslation } from "react-i18next";

/**
 * @module BirthDate
 * @description Component for managing the birth date of a guest.
 * This component includes an input field for selecting the birth date
 * with validation based on required fields.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {function} props.handleGuestChange - Function to handle changes in guest data.
 * @param {Object} props.guest - The guest object containing birth date data.
 * @param {Array<Object>} props.requiredFields - List of required fields for validation,
 *                                               where each object contains:
 *                                               - {string} Field - The field name.
 *                                               - {boolean} IsMandatory - Indicates if the field is required.
 *
 * @returns {JSX.Element} The rendered birth date input field.
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @version 1.0.0
 *
 * @example
 * // Example usage of the BirthDate component
 * <BirthDate
 *   handleGuestChange={handleGuestChange}
 *   guest={guestData}
 *   requiredFields={requiredFields}
 * />
 */
export const BirthDate = ({handleGuestChange, guest, requiredFields}) => {
  const [t] = useTranslation("global");

    // Determine if the BirthDate field is required based on the provided validation rules
    const isRequired = requiredFields.some(
      field => field.Field === "BirthDate" && field.IsMandatory);

    /**
     * @description Formats the date string into 'yyyy-MM-dd' format.
     *
     * @function formatDate
     * @param {string} dateString - The date string to format.
     * @returns {string} The formatted date string in 'yyyy-MM-dd' format or an empty string if invalid.
     *
     * @example
     * // Example usage of formatDate
     * const formattedDate = formatDate(guest.data.BirthDate);
     */
      const formatDate = (dateString) => {
      if (!dateString) return ""; // Return an empty string if the date is null or undefined
      const date = new Date(dateString);
      return date.toISOString().split('T')[0]; // Get only the date part in 'yyyy-MM-dd' format
    };

  return (
    <div>
    <label htmlFor="dataNascimento" className="pl-2 pr-2 rounded-xl inline-block translate-y-3.5 text-center justify-center translate-x-3 bg-white text-sm font-medium text-gray-700 mb-1" >{`${t(`Birthdate.birthdate`)}`}<span className="text-gray-400 ml-2">{isRequired? "": `${t(`FormFields.optional`)}`}</span></label>
    <input name="BirthDate" required={isRequired} onChange={(e)=>handleGuestChange(guest.id,e.target.name,e.target.value)} value={formatDate(guest.data.BirthDate) || ""} type="date" id="dataNascimento" className="w-full px-4 py-2 rounded-lg border border-gray-700 focus:ring-2 text-xl focus:border-blue-500 transition duration-150 ease-in-out" />
  </div>  )
}
