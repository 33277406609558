import { useContext } from 'react'
import { AppContext } from '../../../context/AppContext';
import { Link } from 'react-router-dom';


export const RestaurantCard = ({serviceName, serviceImage, serviceStatus, hotelId, serviceId}) => {

  const [state] = useContext(AppContext);

  return (
    <Link to={`/hotel/${hotelId}/restaurant/${serviceId}`} >
        <div className={`${state.darkMode?'':'car_border_color card_hover_bg_color'} bg-white max-w-sm border rounded-lg mb-4 mr-4 shadow dark:bg-gray-800 dark:border-gray-700 w-full sm:w-[360px]`}>
            <img className="rounded-t-lg h-[250px] w-full overflow-hidden object-fit" src={serviceImage} alt="restaurant" />
            <div className="p-5">
                <h5 className={`${state.darkMode?'':'car_title_color'} mb-2 text-2xl font-bold tracking-tight dark:text-white`}>{serviceName}</h5>
                <div className="flex flex-wrap">
                    <label className={`w-[12px] h-[12px] ${serviceStatus==="Open" && "bg-green-500 dark:bg-white"} ${serviceStatus==="Closed" && "bg-gray-500 dark:bg-white"} rounded-full my-auto mr-2`}></label>
                    <label className={`${serviceStatus==="Open" && "text-green-500 dark:bg-white"} ${serviceStatus==="Closed" && "text-gray-900 dark:bg-white"}`}>{serviceStatus}</label>
                </div>
            </div>
        </div>
    </Link>
  )
}
