import { useState, useContext, useEffect } from "react";
import { PlusIcon, TrashIcon } from "lucide-react";
import { HostForm } from "./HostForm";
import { AppContext } from "../../../context/AppContext";
import { getHotelInitialData, saveReservationGuests } from "../../../services";
import { toast } from "react-toastify";
import { Consent } from "./Consent";
import { GeneralTerms } from "./GeneralTerms";
import { ArrivalTime } from "./ArrivalTime";
import { SquarePen } from "lucide-react";
import { Link } from "react-router-dom";
import { Loading } from "../../Loading";
import { useTranslation } from "react-i18next";
import onlineCheckInDesktop from "../../../assets/images/onlineCheckInDesktop.jpeg"
import onlineCheckIn from "../../../assets/images/onlineCheckIn.jpeg"
import hhsLogo from "../../../assets/images/hhsLogo.png"
import ProgressBar from "./ProgressBar";
import Stepper from "./ProgressBar/Stepper";


/**
 * @module HostFormComplete
 * @description Renders the host form for guests' online check-in, allowing adding and removing guests, and submitting their information.
 * @param {Object} props - Component props.
 * @param {Object} props.hotel - Hotel data.
 * @param {Object} props.reservation - Reservation data.
 * @param {function} props.setReservation - Function to update the reservation state.
 * @returns {JSX.Element} Rendered component.
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @version 1.0.0
 * @example
 * <HostFormComplete hotel={hotel} reservation={reservation} setReservation={setReservation} />
 */
export const HostFormComplete = ({ hotel, reservation, setReservation }) => {
  const [guests, setGuests] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [state] = useContext(AppContext);
  const [initialData, setInitialData] = useState();
  const [showConsent, setShowConsent] = useState(false);
  const [showGeneralTerms, setShowGeneralTerms] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);
  const [successSubmit, setSuccessSubmit] = useState(false);
  const [t] = useTranslation("global");
  const [step, setStep] = useState(1)
  const [totalSteps, setTotalSteps] = useState()

  /**
   * @function addNewGuest
   * @description Adds a new guest to the guests list and sets the active tab to the new guest.
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @version 1.0.0
   * @example
   * addNewGuest();
   */
  const addNewGuest = () => {
    const newGuest = {
      id: guests.length,
      title: `${t(`HostFormComplete.guest`)} ${guests.length + 1}`,
      data: {},
    };
    setGuests([...guests, newGuest]);
    setActiveTab(newGuest.id);
  };

  /**
   * @function removeGuest
   * @description Removes a guest from the guests list based on the provided ID.
   * @param {number} id - The ID of the guest to remove.
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @version 1.0.0
   * @example
   * removeGuest(1);
   */
  const removeGuest = (id) => {
    if (id === 0) return; // Do not allow removal of the main guest
    const newGuests = guests.filter((guest) => guest.id !== id);
    setGuests(newGuests);
    setActiveTab(newGuests[newGuests.length - 1].id);
  };

  /**
   * @function extractData
   * @description Extracts guest data from the guests list.
   * @param {Array} guests - List of guest objects.
   * @returns {Array} Array of guest data objects.
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @version 1.0.0
   * @example
   * const data = extractData(guests);
   */
  function extractData(guests) {
    return guests.map((guest) => guest.data);
  }

  /**
   * @function handleSubmit
   * @description Handles form submission, checking for required data and saving the reservation guests.
   * @param {Event} e - The form submit event.
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @version 1.0.0
   * @example
   * handleSubmit(event);
   */
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!guests[0].data.GeneralTermsAccepted) {
      alert(`${t(`HostFormComplete.acceptgeneral`)}`);
      return;
    }
    if (!guests[0].data.ArrivalTime) {
      alert(`${t(`HostFormComplete.arrivaltime`)}`);
      return;
    }
    if (canSubmit) {
      saveReservationGuests(hotel, extractData(guests))
        .then((data) => {
          setSuccessSubmit(true);
        })
        .catch((error) => {
          alert(`${t(`HostFormComplete.formfail`)}`, error);
        });
    } else {
      alert(`${t(`HostFormComplete.arrivaltime`)}`);
    }
  };

  /**
   * @function next
   * @description Handles the logic for moving to the next guest or showing the consent or general terms.
   * @param {Event} e - The event triggered by the button click.
   * @param {number} id - The current guest index.
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @version 1.0.0
   * @example
   * next(event, guestId);
   */
  const next = (e, id) => {
    e.preventDefault();
    if (!showGeneralTerms && showConsent && id === guests.length - 1) {
      setShowGeneralTerms(true);
      setShowConsent(false);
      setStep(step+1)
      window.scrollTo(0, 0);
      return;
    }
    if (!showGeneralTerms && !showConsent && id === guests.length - 1) {
      setShowConsent(true);
      setActiveTab(0);
      setStep(step+1)
      window.scrollTo(0, 0);
      return;
    }
    setActiveTab(id + 1);
    setStep(step+1)
    window.scrollTo(0, 0);

  };

  /**
   * @function previous
   * @description Handles the logic for moving to the previous guest or showing the consent or general terms.
   * @param {Event} e - The event triggered by the button click.
   * @param {number} id - The current guest index.
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @version 1.0.0
   * @example
   * previous(event, guestId);
   */
  const previous = (e, id) => {
    e.preventDefault();
    if (showGeneralTerms && !showConsent && id === 0) {
      setShowConsent(true);
      setShowGeneralTerms(false);
      setActiveTab(guests.length - 1);
      setStep(step-1)
      window.scrollTo(0, 0);
      return;
    }
    if (!showGeneralTerms && showConsent && id === 0) {
      setShowConsent(false);
      setActiveTab(guests.length - 1);
      setStep(step-1)
      window.scrollTo(0, 0);
      return;
    }
    setActiveTab(id - 1);
    setStep(step-1)
    window.scrollTo(0, 0);
  };

  /**
   * @events useEffectSetGuests
   * @description Sets the guests state based on the reservation data.
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @version 1.0.0
   * @example
   * useEffect(() => {
   *   // Logic here
   * }, [reservation]);
   */
  useEffect(() => {
    if (reservation) {
      if (Array.isArray(reservation.Guests)) {
        // Store the number of adults
        const numberOfAdults = reservation.Adults || 0;
        const numberOfGuests = reservation.Guests.length;

        // If the number of guests equals the number of adults, keep it as is
        if (numberOfGuests === numberOfAdults) {
          setGuests(
            reservation.Guests.map((guestData, index) => ({
              id: index,
              title: index === 0 ? `${t(`HostFormComplete.mainguest`)}` : `${t(`HostFormComplete.guest`)} ${index + 1}`,
              data: guestData,
            }))
          );
        } else {
          // If the number of guests is less than the number of adults, copy the first guest
          const mainGuest = reservation.Guests[0];

          const newGuests = reservation.Guests.map((guestData, index) => ({
            id: index,
            title: index === 0 ? `${t(`HostFormComplete.mainguest`)}` : `${t(`HostFormComplete.guest`)} ${index + 1}`,
            data: guestData,
          }));

          // Add empty guests until the number of guests equals the number of adults
          for (let i = numberOfGuests; i < numberOfAdults; i++) {
            newGuests.push({
              id: i,
              title: `${t(`HostFormComplete.guest`)} ${i + 1}`,
              data: {
                ...mainGuest,
                FirstName: "",
                LastName: "",
                Email: "",
                isMainGuest: false,
                GuestId: "-1",
                GuestUid: "",
              },
            });
          }
          setGuests(newGuests);
        }
      }
    }
  }, [reservation]);

  /**
   * @events useEffectUpdateReservation
   * @description Updates the reservation state with the guests' data when the guests change.
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @version 1.0.0
   * @example
   * useEffect(() => {
   *   // Logic here
   * }, []);
   */
  useEffect(() => {
    setReservation((prevReservation) => ({
      ...prevReservation,
      Guests: guests.map((guest) => guest.data), // Updating only with guest data
    }));
  }, []); // Triggered when `guests` changes

  /**
   * @events useEffectFetchHotel
   * @description Fetches initial hotel data when the hotel prop changes.
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @version 1.0.0
   * @example
   * useEffect(() => {
   *   // Logic here
   * }, [hotel]);
   */
  useEffect(() => {
    if (hotel) {
      getHotelInitialData(hotel)
        .then((data) => {
          setInitialData(data.InitialData);
        })
        .catch((error) => {
          console.error(`${t(`OnlineCheckIn.errorhotel`)}`, error);
          toast.error(`${t(`OnlineCheckIn.errorhotel`)}`);
        });
    }
  }, [hotel]); // eslint-disable-line

  useEffect(()=>{
    setTotalSteps(guests.length*2+1)
  },[guests])

  if (successSubmit) {
    // Success component
    return (
      <div className="text-center">
        <div className={`${ state.darkMode ? "" : "sections_bg_color sections_border_color" } px-8 py-6 rounded-xl shadow-lg mx-auto`} >
          <h2 className={`${ state.darkMode ? "" : "sections_title_color" } text-3xl font-bold text-gray-800 mb-4`} >{`${t(`HostFormComplete.checkin`)}`}</h2>
          <div className="flex justify-between items-center"> <h2 className="text-xl font-bold mb-4">{`${t(`HostFormComplete.success`)}`}</h2> <SquarePen onClick={() => setSuccessSubmit(false)} className="cursor-pointer" /> </div>

          <div className="bg-green-100 text-green-700 p-4 rounded-lg mb-4 flex justify-between">
            <p>{`${t(`HostFormComplete.thankyou`)}`}{guests[0].data?.FirstName ||`${t(`HostFormComplete.guest`)}`}{`${t(`HostFormComplete.formsuccess`)}`}</p>
            <Link to={`/hotel/${hotel.pmsId.substring(0, 8)}`}> {" "} <b>{`${t(`HostFormComplete.backhotel`)}`}</b> </Link>
          </div>
        </div>
      </div>
    );
  }

  // style={{ backgroundImage: window.innerWidth >= 640 ? `url(${onlineCheckInDesktop})` : `url(${onlineCheckIn})`, backgroundSize: 'auto'}}

  return (
    <>

      {reservation && hotel && guests.length > 0 ? (
        <form id="onlineCheckinForm" onSubmit={handleSubmit} className={` ${ state.darkMode ? "" : "sections_bg_color sections_border_color" } mx-auto pb-6`} >
          <div className="flex justify-center">
            <img src={hhsLogo} className={`absolute translate-y-[70px] `} />
          </div>
          <img src={window.innerWidth >= 640 ? onlineCheckInDesktop: onlineCheckIn} />
          <div className="flex max-sm:justify-center">
            <h1 className={` absolute text-5xl -translate-y-[70px] sm:-translate-y-[70px] sm:translate-x-[30px] font-bold text-white mb-2 sm:mb-4`} >{`${t(`HostFormComplete.checkin`)}`}</h1>
          </div>
          <div className="flex flex-col justify-center">
            <ProgressBar step={step} totalSteps={totalSteps} />
            <Stepper step={step} totalSteps={totalSteps} />
          </div>
          {/* <div className="">
            {!showGeneralTerms && guests.length > 0 && (
              <div className="flex space-x-2 border-gray-200 overflow-x-auto">
                {guests.map((guest) => (
                  <div key={guest.id} className="flex items-center">
                    <button type="button" className={`py-2 px-4 font-medium text-sm focus:outline-none ${ activeTab === guest.id ? "border-b-2 border-blue-500 text-blue-600" : "text-gray-500 hover:text-gray-700" }`} onClick={() => setActiveTab(guest.id)} >
                      {guest.title}
                    </button>{" "}
                    {guest.id !== 0 && (
                      <button type="button" className="ml-2 text-red-500 hover:text-red-700 focus:outline-none" onClick={() => removeGuest(guest.id)} >
                        <TrashIcon size={16} />
                      </button>
                    )}
                  </div>
                ))}
                <button type="button" className="py-2 px-4 text-blue-600 hover:text-blue-700 focus:outline-none" onClick={addNewGuest} >
                  <PlusIcon size={20} />
                </button>
              </div>
            )}
          </div> */}
          {guests.map((guest, index) => (
            <div key={guest.id} style={{ display: activeTab === guest.id ? "block" : "none" }} >
              {!showConsent && !showGeneralTerms && (
                <HostForm activeTab={activeTab} showGeneralTerms={showGeneralTerms} showConsent={showConsent} previous={previous} next={next} isMain={index === 0} mainGuest={guests[0]} guest={guest} guests={guests} setGuests={setGuests} initialData={initialData} />
              )}
              {showConsent && !showGeneralTerms && (
                <Consent activeTab={activeTab} showGeneralTerms={showGeneralTerms} showConsent={showConsent} previous={previous} next={next} guest={guest} guests={guests} setGuests={setGuests} />
              )}
              {showGeneralTerms && !showConsent && (
                <GeneralTerms setCanSubmit={setCanSubmit} activeTab={activeTab} showGeneralTerms={showGeneralTerms} showConsent={showConsent} previous={previous} next={next} guests={guests} setGuests={setGuests} initialData={initialData} />
              )}
            </div>
          ))}
          <ArrivalTime guests={guests} setGuests={setGuests} />
          {guests.length > 0 && reservation && showGeneralTerms && (
            <div className="flex justify-center">
              <button onSubmit={(e) => handleSubmit(e)} className={`w-auto px-6 py-3 mt-5  ${ canSubmit ? "bg-blue-600" : "bg-gray-400 cursor-not-allowed" } bg-blue-600 text-white font-medium rounded-lg shadow-md transition duration-150 ease-in-out`} >
                  {`${t(`HostFormComplete.submit`)}`}
              </button>
            </div>
          )}
        </form>
      ) : (
        <Loading />
      )}
    </>
  );
};
