import i18next from "i18next";
import { useState } from "react";

export const LanguageDropdown = () => {

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState({
        label: "United States (en-us)",
        icon: <span className="fi fi-us fis h-3.5 w-3.5 rounded-full mr-2"></span>,
      });

  function changeLanguage(lang) {
    i18next.changeLanguage(lang);
  }

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  const handleSelect = (option) => {
    setSelectedOption(option);
    setDropdownOpen(false);
  };

  const options = [
    { label: "Portuguese (pt-pt)", icon: <span className="fi fi-pt fis h-3.5 w-3.5 rounded-full mr-2"></span>, value: "pt_pt", onClick: () => changeLanguage("pt_pt"), },
    { label: "United States (en-us)", icon: <span className="fi fi-us fis h-3.5 w-3.5 rounded-full mr-2"></span>, value: "en_us", onClick: () => changeLanguage("en_us"), },
  ];

  return (
    <form className="max-w-sm mx-auto w-52">
    <div className="relative">
      <button id="dropdownButton" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" type="button" aria-haspopup="true" aria-expanded={dropdownOpen} onClick={toggleDropdown} >
      {selectedOption.icon}
      {selectedOption.label}
      </button>
      {dropdownOpen && (
          <div className="absolute z-10 mt-1 w-full bg-white dark:bg-gray-700 rounded-lg shadow-lg">
            {options.map((option) => (
              <div key={option.value} className="block py-2 px-4 cursor-pointer text-sm rounded-lg text-gray-700 hover:bg-gray-100 dark:hover:text-white dark:text-gray-300 dark:hover:bg-gray-600" onClick={() => { handleSelect(option); option.onClick(); }} >
                <div className="inline-flex items-center"> {option.icon} {option.label} </div>
              </div>
            ))}
          </div>
        )}
    </div>
  </form>
  );
};
