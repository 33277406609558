import React, { createContext, useReducer } from "react";
import Reducer from "../reducers/AppReducer"

const initialState = {
    darkMode: (JSON.parse(localStorage.getItem(`darkMode`)) || false),
    hiddenMobileSidebar: true,
    userLoggedIn: false,
    userDetails:null,
    currentHotel: {
          name1: null,
          name2: null,
          contact: null,
          address1: null,
          address2: null,
          zipCode: null,
          city: null,
          countryCode: null,
          countryDescription: null,
          fiscalNumber: null,
          phone: null,
          email: null,
          webSite: null,
          defaultCurrencyCode: null,
          defaultCultureCode: null,
          defaultTimeZone: null,
          hotelDate: null,
          licenseCode: null,
          licenseCode2: null,
          licenseValidUntil: null,
          licenseFlag1: null,
          licenseFlag2: null,
          licenseFeatures: null,
          licensedRooms: null,
          licensedPos: null,
          licensePosLocation: null,
          sortOrder: null,
          deleted: false,
          pmsTimestamp: null,
          pmsId: null,
          hotelGroupId: null,
          pmsType: null,
          currentVersion: null,
          lastServicePack_File_Ordinal: null,
          lastServicePack_File_Timestamp: null,
          lastServicePack_Sql_Ordinal: null,
          lastServicePack_Sql_Timestamp: null,
          apiEndPoint: null,
          updatedOn: null,
          hotfixInfo: null,
          subscriptionKey: null,
          groupId: null
    },
     defaultTheme:{
          /* Sidebar */
          sidebar_title_color: "#111827",
          sidebar_text_color: "#111827",
          sidebar_icons_color: "#111827",
          sidebar_bg_color: "#FFFFFF",
          sidebar_border_color: "#D1D5DB",
          sidebar_hover_bg_color: "#F3F4F6",
          sidebar_hover_text_color: "#111927",
          /* Navbar */
          navbar_icons_color: "#111827",
          navbar_bg_color: "#FFFFFF",
          navbar_border_color: "#D1D5DB",
          navbar_hover_bg_color: "#F3F4F6",
          navbar_hover_text_color: "#111927",
          /* Footer */
          footer_text_color: "#111827",
          /* Page */
          page_bg_color: "#F3F4F6",
          /* Sections */
          sections_title_color: "#111827",
          sections_text_color: "#111827",
          sections_icons_color: "#111827",
          sections_bg_color: "#FFFFFF",
          sections_border_color: "#D1D5DB",
          sections_hover_bg_color: "#F3F4F6",
          sections_hover_text_color: "#111927",
          /* Cards */
          card_title_color: "#111827",
          card_text_color: "#111827",
          card_icons_color: "#111827",
          card_bg_color: "#FFFFFF",
          card_border_color: "#D1D5DB",
          card_hover_bg_color: "#F3F4F6",
          card_hover_text_color: "#111927",
     }
}

export const Store = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, initialState);

  return (
    <AppContext.Provider value={[state, dispatch]}>
      {children}
    </AppContext.Provider>
  );
};

export const AppContext = createContext(initialState);

