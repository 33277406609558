import { Link } from "react-router-dom";
import { useContext } from "react";
import { AppContext } from "../../../context/AppContext";

export const MenuCard = ({ id, title, ingredients, price }) => {
  const [state] = useContext(AppContext);

  return (
    <Link to={`/hotel/:hotelId/restaurant/:serviceId`} key={id}>
      <div className={`flex justify-between py-4 px-2 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700`} >
        <div className="flex-1">
            <div className="flex flex-row items-center" >
                <h5 className={`${state.darkMode?'':'card_border_color card_title_color'} text-xl mr-4 font-bold tracking-tight max-sm:text-sm dark:text-white`}> {title} </h5>
            </div>
          <p className="font-normal text-sm text-gray-700 dark:text-gray-400"> {ingredients} </p>
        </div>
        <div className="flex justify-center align-middle">
            <div className="flex flex-row items-center" >
                <h5 className={`${state.darkMode?'':'card_border_color card_title_color'} text-xl mr-4 font-bold tracking-tight max-sm:text-sm dark:text-white`}> {price} </h5>
            </div>
        </div>
        {/* <div className="flex flex-col justify-center">
          <svg className="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" >
            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m9 5 7 7-7 7" />
          </svg>
        </div> */}
      </div>
    </Link>
  );
};
