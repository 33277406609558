import  { useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../context/AppContext'
import { Link } from 'react-router-dom'
import { useRefs } from '../../context/RefsContext';

import "/node_modules/flag-icons/css/flag-icons.min.css";
import "../css/Sidebar.css"
import { InstallButton } from './components/Buttons/InstallButton';

export const Sidebar = () => {

  const [state, dispatch] = useContext(AppContext);
  const [t] = useTranslation("global")
  const { sidebarRef } = useRefs()
  const { searchSidebarRef } = useRefs()
  const { toggleSidebarRef }= useRefs()

  function closeMobileSidebar(e){
    if (sidebarRef.current && !sidebarRef.current.contains(e.target) && !toggleSidebarRef.current.contains(e.target)) {
      dispatch({type:"TOGGLE_MOBILE_SIDEBAR", payload:true})
    }
  }

  useEffect(() => {
    if (!state.hiddenMobileSidebar){
    document.addEventListener('mousedown', closeMobileSidebar);
    return () => {
      document.removeEventListener('mousedown', closeMobileSidebar);
    }}

  }, [state.hiddenMobileSidebar===false]);// eslint-disable-line

  return (
    <>
    {sidebarRef && searchSidebarRef && state.currentHotel.pmsId &&
      <div className="antialiased dark:bg-gray-900">
          <aside ref={sidebarRef} className={`${state.darkMode?'':'sidebar_bg_color sidebar_border_color'} fixed top-0 left-0 z-40 w-64 h-screen pt-14 transition-transform md:translate-x-0 border-r ${state.hiddenMobileSidebar?'-translate-x-full':'translate-x-0'} dark:bg-gray-800 dark:border-gray-700`} aria-label="Sidenav" id="drawer-navigation">
            <div className="py-5 px-3 h-full dark:bg-gray-800">
              {/*Sidebar-search*/}
              <form action="#" method="GET" className="md:hidden mb-2">
                <label htmlFor="sidebar-search" className="sr-only">Search</label>
                <div className="relative">
                  <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                    <svg className="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" >
                      <path fillRule="evenodd" clipRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" ></path>
                    </svg>
                  </div>
                  <input ref={searchSidebarRef} type="text" name="search" id="sidebar-search" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-10 p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Search" />
                </div>
              </form>
              {/*Sidebar-Overview*/}
              <ul className="space-y-2">
                <li>
                  <Link to="/" className={` ${state.darkMode?'':'sidebar_hover_bg_color sidebar_hover_text_color sidebar_icons_color'} flex items-center p-2 text-base font-medium rounded-lg dark:text-white dark:hover:bg-gray-700 group`} >
                    <svg aria-hidden="true" className={`${state.darkMode?'':'sidebar_icons_color sidebar_hover_text_color '} w-6 h-6 dark:group-hover:text-white`} fill={"currentColor"} viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" >
                      <path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z"></path>
                      <path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z"></path>
                    </svg>
                    <span className={`${state.darkMode?'':'sidebar_text_color sidebar_hover_text_color '} flex-1 ml-3 text-left whitespace-nowrap dark:group-hover:text-white`}>{t("overview")}</span>
                  </Link>
                </li>
              </ul>
              {/*Hotel Name*/}
              <ul className="space-y-2">
                <li >
                  <Link to={`/hotel/${state.currentHotel.pmsId.substring(0, 8)}`} className="flex flex-wrap items-center w-54 p-2 text-base font-medium rounded-lg dark:text-white group" >
                     {state.currentHotel && <span style={{color: state.darkMode?"":'sidebar_title_color'}} className={`h-auto text-wrap text-left text-xl`}>{state.currentHotel.name2}</span>}
                  </Link>
                </li>
              </ul>
              {/*Sidebar-Pages*/}
              <ul className="space-y-2">
                { state.currentHotel.pmsId && (<li >
                    <Link to={`/hotel/${state.currentHotel.pmsId.substring(0, 8)}/restaurantandbars`} className={` ${state.darkMode?'':'sidebar_hover_bg_color'} flex items-center p-2 text-base font-medium rounded-lg dark:text-white dark:hover:bg-gray-700 group `} >
                      <svg className={`${state.darkMode?'':'sidebar_icons_color sidebar_hover_text_color '} w-6 h-6 dark:text-gray-400`} xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill={"currentColor"} viewBox="0 0 640 512">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M96 64c0-17.7 14.3-32 32-32h320 64c70.7 0 128 57.3 128 128s-57.3 128-128 128h-32c0 53-43 96-96 96H128c-53 0-96-43-96-96V64zM480 224h32c35.3 0 64-28.7 64-64s-28.7-64-64-64h-32v128zM32 416h512c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32z"/>
                      </svg>
                      <span className={`${state.darkMode?'':'sidebar_hover_text_color sidebar_text_color'} flex-1 ml-3 text-left whitespace-nowrap`}>{t("restaurant_and_bars")}</span >
                    </Link>
                  </li>)}
                  { state.currentHotel.pmsId && (<li >
                    <Link to={`/hotel/${state.currentHotel.pmsId.substring(0, 8)}/wellness`} className={` ${state.darkMode?'':'sidebar_hover_bg_color'} flex items-center p-2 text-base font-medium rounded-lg dark:text-white dark:hover:bg-gray-700 group `} >
                      <svg className={`${state.darkMode?'':'sidebar_icons_color sidebar_hover_text_color '} w-6 h-6 dark:text-gray-400`} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill={"currentColor"} viewBox="0 0 24 24">
                        <path d="M4 11.9998H8L9.5 8.99976L11.5 13.9998L13 11.9998H15M12 6.42958C12.4844 5.46436 13.4683 4.72543 14.2187 4.35927C16.1094 3.43671 17.9832 3.91202 19.5355 5.46436C21.4881 7.41698 21.4881 10.5828 19.5355 12.5354L12.7071 19.3639C12.3166 19.7544 11.6834 19.7544 11.2929 19.3639L4.46447 12.5354C2.51184 10.5828 2.51184 7.41698 4.46447 5.46436C6.0168 3.91202 7.89056 3.43671 9.78125 4.35927C10.5317 4.72543 11.5156 5.46436 12 6.42958Z" stroke={`${state.darkMode?'#000000':'#FFFFFF'}`} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                      </svg>
                      <span className={`${state.darkMode?'':'sidebar_hover_text_color sidebar_text_color'} flex-1 ml-3 text-left whitespace-nowrap`}>{t("wellness")}</span >
                    </Link>
                  </li>)}
              </ul>
            </div>
            {/*Sidebar-bottom group icons*/}
            <div className={`${state.darkMode?'':'sidebar_bg_color sidebar_border_color'} sticky bottom-0 left-0 justify-center p-4 w-full flex flex-col dark:`} >
              <InstallButton />
              <div className="sticky bottom-0 left-0 justify-center space-x-4 p-4 w-full flex z-20" >
                <Link to="/theme" className={`${state.darkMode?'':'sidebar_hover_text_color sidebar_icons_color sidebar_hover_bg_color'} inline-flex justify-center p-2  rounded cursor-pointer dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600`} >
                  <svg aria-hidden="true" className={`w-6 h-6`} fill={"currentColor"} viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" >
                    <path d="M5 4a1 1 0 00-2 0v7.268a2 2 0 000 3.464V16a1 1 0 102 0v-1.268a2 2 0 000-3.464V4zM11 4a1 1 0 10-2 0v1.268a2 2 0 000 3.464V16a1 1 0 102 0V8.732a2 2 0 000-3.464V4zM16 3a1 1 0 011 1v7.268a2 2 0 010 3.464V16a1 1 0 11-2 0v-1.268a2 2 0 010-3.464V4a1 1 0 011-1z" ></path>
                  </svg>
                </Link>
                <Link to={"/settings"} data-tooltip-target="tooltip-settings" className={`${state.darkMode?'':'sidebar_hover_text_color sidebar_icons_color sidebar_hover_bg_color'} inline-flex justify-center p-2 rounded cursor-pointer dark:text-white dark:hover:text-white dark:hover:bg-gray-600`} >
                  <svg aria-hidden="true" className={`w-6 h-6`} fill={"currentColor"}  viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" >
                    <path fillRule="evenodd" d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z" clipRule="evenodd" ></path>
                  </svg>
                </Link>
              </div>
            </div>
          </aside>
      </div>
    }
    </>
  )
}
