import { AppContext } from "../../../context/AppContext";
import { useContext, useEffect, useState } from "react";

export const ColorPicker = ({variable, variable_state}) => {

  const [state, dispatch] = useContext(AppContext);
  const [color, setColor] = useState(getComputedStyle(document.documentElement).getPropertyValue(variable));


  var root = document.querySelector(':root');

  function handleColorChange(event) {
    const newColor = event.target.value;  // Get new color from input
    setColor(newColor);  // Update state with the new color
    dispatch({ type: "SET_DEFAULT_THEME", payload: {[variable_state]: newColor}});
    return newColor;
  }

  console.log([variable_state])

  function atualizarTema(newColor) {
    if (state.selectedHotel && state.selectedHotel.id) {
      root.style.setProperty(variable, newColor);  // Update the CSS variable in :root
    }
  }

  useEffect(() => {
    atualizarTema(color);  // Call function to update the theme whenever the color changes
  }, [color]);  // eslint-disable-line


  return (
    <input type='color' value={color} onChange={handleColorChange}/>
  )
}
