import { useState, useEffect } from "react";
import { ArrowBigLeft, ArrowBigLeftDash, ArrowBigRightIcon, CopyIcon } from "lucide-react";
import { FirstName } from "./FormFields/FirstName";
import { LastName } from "./FormFields/LastName";
import { DocumentType } from "./FormFields/DocumentType";
import { DocumentNumber } from "./FormFields/DocumentNumber";
import { DocumentExpireDate } from "./FormFields/DocumentExpireDate";
import { BirthDate } from "./FormFields/BirthDate";
import { Gender } from "./FormFields/Gender";
import { Nationality } from "./FormFields/Nationality";
import { Email } from "./FormFields/Email";
import { Phone } from "./FormFields/Phone";
import { Nif } from "./FormFields/Nif";
import { Address } from "./FormFields/Address";
import { useTranslation } from "react-i18next";
import NameForm from "./FormFields/NameForm";

/**
 * @module HostForm
 * @description This component allows the user to enter and manage the host's information, including
 * email, phone number, and address. It provides functionality to update the guest's data and validates the email format.
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @version 1.0.0
 *
 * @param {Object} props - The properties passed to the component.
 * @param {boolean} props.isMain - Indicates if this is the main host.
 * @param {Object} props.guest - The guest object containing guest data.
 * @param {Function} props.setGuests - Function to update the guests state.
 * @param {Object} props.initialData - Initial data required for the component.
 * @returns {JSX.Element} The rendered component.
 */
export const HostForm = ({ activeTab, showConsent, showGeneralTerms, previous, next, isMain, mainGuest, guest, guests, setGuests, initialData, }) => {
  const [nifErrors, setNifErrors] = useState({}); // State to store NIF errors for each guest
  const [nifValues, setNifValues] = useState({}); // State to store NIFs for each guest
  const [requiredFields, setRequiredFields] = useState( initialData.GuestFields.flat() );
  const [isContinueDisabled, setIsContinueDisabled] = useState(false);
  const [t] = useTranslation("global");

  /**
   * @function handleGuestChange
   * @description Updates a specific field in the guest's data.
   * This function modifies the data of a guest identified by the provided ID.
   * It takes the field name to be updated and the new value, then it updates
   * the guest's data in the state by mapping over the current guests.
   * If the guest's ID matches the provided ID, it creates a new object
   * with the updated field, leaving other fields unchanged.
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @version 1.0.0
   * @param {string|number} id - The unique identifier of the guest whose data is to be updated.
   * @param {string} fieldName - The name of the field to be updated in the guest's data.
   * @param {any} value - The new value to set for the specified field.
   * @returns {void}
   *
   * @example
   * // To update the phone number of a guest with ID 1:
   * handleGuestChange(1, "Phone", "+351 123 456 789");
   */
  const handleGuestChange = (id, fieldName, value) => {
    setGuests((prevGuests) =>
      prevGuests.map((guest) =>
        guest.id === id
          ? { ...guest, data: { ...guest.data, [fieldName]: value } }
          : guest
      )
    );
  };

  /**
   * @function copyAddressData
   * @description Copies address data from the main guest to the specified guest index.
   * This function updates the specified guest's address fields (CountryId, CountryIso,
   * Address1, Town, ZipCode) to match the main guest's address data.
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @version 1.0.0
   * @param {number} guestIndex - The index of the guest to update.
   * @param {Event} e - The event that triggered this function, used to prevent default behavior.
   * @returns {void}
   */
  const copyAddressData = (guestIndex, e) => {
    e.preventDefault();

    setGuests((prevGuests) => {
      const updatedGuests = [...prevGuests]; // Create a copy of the previous state

      // Check if the guest index is valid
      if (updatedGuests[guestIndex]) {
        updatedGuests[guestIndex] = {
          ...updatedGuests[guestIndex], // Keep current guest data
          data: {
            ...updatedGuests[guestIndex].data, // Keep current guest data
            CountryId: mainGuest.data.CountryId,
            CountryIso: mainGuest.data.CountryIso,
            Address1: mainGuest.data.Address1,
            Town: mainGuest.data.Town,
            ZipCode: mainGuest.data.ZipCode,
          },
        };
      }

      return updatedGuests; // Return the updated state
    });
  };

  /**
   * @function ValidateNIF
   * @description Validates a NIF (Número de Identificação Fiscal) for Portugal or other countries.
   * This function checks the validity of the provided NIF based on the country specified.
   * For Portugal, it checks the format and the checksum of the NIF. For other countries,
   * it performs basic checks on the length and known invalid NIFs.
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @version 1.0.0
   * @param {string} nif - The NIF to validate, which may contain spaces or other characters.
   * @param {string} country - The country code to determine validation rules (e.g., "PT" for Portugal).
   * @returns {boolean} Returns true if the NIF is valid, otherwise returns false.
   */
  const ValidateNIF = (nif, country) => {
    if (!nif || typeof nif !== "string") {
      return false; // Return false if the NIF is invalid
    }

    // Remove all non-numeric characters from the NIF
    nif = nif.replace(/[^\d]+/g, "");

    if (country === "PT") {
      // NIF validation for Portugal (9 digits)
      if (nif.length !== 9) return false;

      const initialDigits = ["1", "2", "3", "5", "6", "7", "8", "9"];
      const firstDigit = nif.charAt(0);

      if (!initialDigits.includes(firstDigit)) return false;

      // Calculate the check digit
      let checkDigit = parseInt(firstDigit) * 9;
      for (let i = 2; i <= 8; i++) {
        checkDigit += parseInt(nif.charAt(i - 1)) * (10 - i);
      }

      checkDigit = 11 - (checkDigit % 11);
      if (checkDigit >= 10) checkDigit = 0;

      return checkDigit === parseInt(nif.charAt(8));
    }

    // Return true for NIFs from other countries, if you don't want to validate
    return true; // Change to false if you want it to return false for NIFs from other countries
  };

  /**
   * @function validateNifonBlur
   * @description Validates the NIF when the input loses focus or the nationality changes.
   * This function checks if the NIF is empty, validates it, and updates the NIF errors state
   * based on the validation result. If valid, it updates the guest's NIF in the global state.
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @version 1.0.0
   * @param {string} nif - The NIF to validate.
   * @param {string} country - The country code for validation.
   * @param {string|number} id - The unique identifier of the guest being validated.
   * @returns {void}
   */
  const validateNifonBlur = (nif, country, id) => {
    // Check if the field is empty or null
    if (!nif || nif.trim() === "") {
      // Clear any error if the field is empty
      setNifErrors((prevErrors) => ({
        ...prevErrors,
        [id]: "", // Remove any previous error
      }));
      return; // Return without validation, as the field is empty
    }

    if (!ValidateNIF(nif, country)) {
      // Update the error state for the specific guest
      setNifErrors((prevErrors) => ({
        ...prevErrors,
        [id]: `${t(`HostForm.invalidvat`)}`,
      }));
    } else {
      // Clear the error and update the NIF in the global state
      setNifErrors((prevErrors) => ({ ...prevErrors, [id]: "" }));
      handleGuestChange(id, "FiscalNumber", nif); // Update the NIF
    }
  };

  /**
   * @function validateRequiredFields
   * @description Flattens the structure to ensure access to the list of required fields
   * and checks if all required fields are filled in the guest's data.
   * Returns true if all required fields are filled or if "Fill Later" is checked.
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @version 1.0.0
   * @returns {boolean} True if all required fields are filled; otherwise, false.
   */
  const validateRequiredFields = () => {
    // Flattening the structure to ensure access to the list of required fields
    const flattenedRequiredFields = requiredFields.flat();
    // Check if all required fields are filled
    const allFieldsFilled = flattenedRequiredFields.every((field) => {
      const fieldValue = guest.data[field.Field]; // Get the field value
      return (
        fieldValue !== undefined && fieldValue !== null && fieldValue !== ""
      ); // Check if not undefined, null, or empty string
    });
    // Return true if all required fields are filled or if "Fill Later" is checked
    return allFieldsFilled || guest.data.FillLater;
  };

  /**
   * @function handleContinue
   * @description Handles the continue action by validating required fields
   * and calling the next function if validation passes. Alerts the user if not all fields are filled.
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @version 1.0.0
   * @param {Event} e - The event triggered by the continue action.
   * @returns {void}
   */
  const handleContinue = (e) => {
    e.preventDefault(); // Prevents form submission

    if (validateRequiredFields(e)) {
      next(e, guest.id); // If validation passes, calls the next function
    } else {
      alert(`${t(`HostForm.required`)}`); // Error message
    }
  };

  /**
   * @function useEffectRequiredFieldsChange
   * @description useEffect to revalidate required fields when guest data or required fields change.
   * This effect runs whenever the guest data or required fields change, revalidating the required fields
   * and updating the state for the continue button's enabled/disabled status.
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @version 1.0.0
   */
  useEffect(() => {
    // Whenever the fields or "Fill Later" state changes, revalidate the required fields
    setIsContinueDisabled(!validateRequiredFields());
  }, [guest.data, requiredFields]);

  return (
    <>
      {guest && initialData && (
        <div className="bg-white px-4 py-2 mx-auto">
          <div className="flex justify-between">
            <h2 className="text-3xl font-bold text-gray-800"> {" "} {guest.title}{" "} </h2>
            <div className={` ${ guest.id === 0 ? "hidden" : "" } flex items-center mb-8`} >
              <input name={`FillLater`} type="checkbox" checked={guest.FillLater} onChange={(e) => handleGuestChange(guest.id, e.target.name, e.target.checked) } className={` mr-2`} />
              <span>{`${t(`HostForm.filllater`)}`}</span>
            </div>
          </div>
          <div className="">
            <div className="grid grid-cols-1 md:grid-cols-3 max-sm:gap-y-0 gap-3">
              <FirstName handleGuestChange={handleGuestChange} guest={guest} requiredFields={requiredFields} />
              <LastName handleGuestChange={handleGuestChange} guest={guest} requiredFields={requiredFields} />
              <DocumentType handleGuestChange={handleGuestChange} guest={guest} initialData={initialData} requiredFields={requiredFields} />
              <DocumentNumber handleGuestChange={handleGuestChange} guest={guest} requiredFields={requiredFields} />
              <DocumentExpireDate handleGuestChange={handleGuestChange} guest={guest} requiredFields={requiredFields} />
              <BirthDate handleGuestChange={handleGuestChange} guest={guest} requiredFields={requiredFields} />
              <Gender handleGuestChange={handleGuestChange} guest={guest} requiredFields={requiredFields} />
              <Nationality validateNifonBlur={validateNifonBlur} handleGuestChange={handleGuestChange} guest={guest} nifValues={nifValues} initialData={initialData} requiredFields={requiredFields} />
              <Email handleGuestChange={handleGuestChange} guest={guest} requiredFields={requiredFields} />
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 max-sm:gap-y-0 gap-3">
              <Phone initialData={initialData} guest={guest} handleGuestChange={handleGuestChange} requiredFields={requiredFields} />
              <Nif validateNifonBlur={validateNifonBlur} guest={guest} nifValues={nifValues} nifErrors={nifErrors} setNifErrors={setNifErrors} setNifValues={setNifValues} requiredFields={requiredFields} />
            </div>
            {(isMain || !isMain) && (
              <>
                <div className="flex items-center justify-between mt-4">
                  <h3 className="text-xl font-semibold text-gray-700">{`${t(`HostForm.address`)}`}</h3>
                  {!isMain && (
                    <button onClick={(e) => copyAddressData(guest.id, e)} className="flex items-center text-blue-600 hover:text-blue-700 focus:outline-none" >
                      <CopyIcon size={16} className="mr-1" /> {`${t(`HostForm.copy`)}`}
                    </button>
                  )}
                </div>
                <Address  handleGuestChange={handleGuestChange} initialData={initialData} guest={guest} requiredFields={requiredFields} />
                <div className="flex justify-around mt-6">
                 { !showConsent && !showGeneralTerms && activeTab!==0 &&
                  <button type="button" onClick={(e) => previous(e, guest.id)} className={`py-2 px-4 border ${ !showGeneralTerms && !showConsent && activeTab === 0 ? "bg-gray-400 cursor-not-allowed" : "" } border-gray-300 rounded`} >
                    <ArrowBigLeftDash />
                  </button>}
                  <button type="button" disabled={isContinueDisabled} onClick={handleContinue} className={`py-2 px-4 ${ isContinueDisabled ? "bg-gray-400 cursor-not-allowed" : "bg-blue-600" } ${ showGeneralTerms && !showConsent && activeTab === guests.length - 1 ? "bg-gray-400 cursor-not-allowed" : "bg-blue-600" } text-white rounded`} >
                    <ArrowBigRightIcon />
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};
