export default function register(){

    let swUrl = `${process.env.PUBLIC_URL}/sw.js`

   // Verifica se o navegador suporta Service Workers
   if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      // Registra o Service Worker quando a página é carregada
      navigator.serviceWorker.register(swUrl)
        .then((registration) => {
          console.log('Service Worker registrado com sucesso:', registration);
        })
        .catch((error) => {
          console.error('Falha ao registrar o Service Worker:', error);
        });
    });
  } else {
    console.warn('Service Workers não são suportados neste navegador.');
  }
}