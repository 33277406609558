import { useContext } from "react";
import { AppContext } from "../../../context/AppContext";
import Skeleton from "react-loading-skeleton";


require("dayjs/locale/pt");


export const ReservationDetailsSkeleton = ({reservation}) => {
    const [state] = useContext(AppContext);

  return (
    <div className="sm:min-w-80 ml-6 mr-3 max-sm:ml-0 max-sm:mt-4 max-sm:w-full max-sm:mr-0">
    <h5 className={`${ state.darkMode ? "" : "card_title_color" } mb-3 text-2xl tracking-tight dark:text-white`} ><Skeleton /></h5>
    <p className={`${ state.darkMode ? "" : "card_title_color" } mt-3 font-normal dark:text-white text-lg`} > <Skeleton /></p>
    <p className={`${ state.darkMode ? "" : "card_text_color" } font-normal dark:text-gray-400 text-lg`} > <Skeleton /></p>
    <p className={`${ state.darkMode ? "" : "card_title_color" } mt-3 font-normal dark:text-white text-lg`} ><Skeleton /></p>
    <p className={`${ state.darkMode ? "" : "card_text_color" } font-normal dark:text-gray-400 text-lg`} ><Skeleton /></p>
    <p className={`${ state.darkMode ? "" : "card_title_color" } mt-3 font-normal dark:text-white text-lg`} ><Skeleton /></p>
    <p className={`${ state.darkMode ? "" : "card_text_color" } font-normal dark:text-gray-400 text-lg`} ><Skeleton /></p>
    <div className="flex items-center mt-3">
      <span className={`${ state.darkMode ? "" : "card_title_color" } w-1/2 mb-2 mr-2 font-normal text-lg text-gray-500 dark:text-white`} ><Skeleton /></span>
      <span className={`${ state.darkMode ? "" : "card_title_color" } mb-2 font-normal text-lg text-gray-500 dark:text-white`} ><Skeleton /></span>
    </div>
    <div className="flex items-center">
      <span className={`${ state.darkMode ? "" : "card_text_color" } w-1/2 text-lg mb-2 font-normal dark:text-gray-400`} ><Skeleton /></span>
    </div>
  </div>  )
}
