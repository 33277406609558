import { HotelReservationsCard } from "./HotelReservationsCard";
import { getHostReservation } from "../../../services";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { HotelSkeletonCard } from "./HotelSkeletonCard";
import { useCookies } from "react-cookie";

export const HotelReservationsSection = ({ hotel }) => {

    const [loadingReservation, setLoadingReservation]= useState(true)
    const [cookies] = useCookies(['sUid','profileKey']);
    const [reservations, setReservations] = useState([])

    const fetchReservations = async () => {
      if(hotel){
      try {
        setLoadingReservation(true);
        const results = await getHostReservation(cookies.sUid, cookies.profileKey, hotel);
        setReservations(results.reservations);
      } catch (error) {
        console.log(error)
        toast.error("Unable to get reservations!");
      } finally {
        setLoadingReservation(false);
      }
    }
  }

    useEffect(() => {
      if(hotel){
      fetchReservations();
      }
    }, [hotel]); // eslint-disable-line

    return (
      <>
        {loadingReservation ? (
          <HotelSkeletonCard />
        ) : reservations.length === 0 ? (
          <p>No reservations found.</p>
        ) : (
          <div className="flex flex-wrap">
            {reservations.map((reservation, index) => (
              <HotelReservationsCard key={index} reservation={reservation} hotel={hotel} />
            ))}
          </div>
        )}
      </>
    );
};
