import { useState } from "react";
import "./stepper.css";
import { TiTick } from "react-icons/ti";

const Stepper = ({step, totalSteps}) => {
    const steps = Array.from({ length: totalSteps }, (_, index) => `Step ${index + 1}`);
    const currentStep = step
    const [complete, setComplete] = useState(false);

  return (
    <>
      <div className="flex justify-center">
        {steps?.map((step, i) => (
          <div key={i} className={`step-item ${currentStep === i + 1 && "active"} ${ (i + 1 < currentStep || complete) && "complete" } `} >
            <div className="step">
              {i + 1 < currentStep || complete ? <TiTick size={24} /> : i + 1}
            </div>
            <p className="text-gray-500">{step}</p>
          </div>
        ))}
      </div>
    </>
  );
};

// const Stepper = ({step, totalSteps}) => {
//     const steps = ["Customer Info", "Shipping Info", "Payment", "Step 4"];
//     const [currentStep, setCurrentStep] = useState(step);
//     const [complete, setComplete] = useState(false);
//     return (
//       <>
//         <div className="flex justify-center">
//           {steps?.map((step, i) => (
//             <div key={i} className={`step-item ${currentStep === i + 1 && "active"} ${ (i + 1 < currentStep || complete) && "complete" } `} >
//               <div className="step">
//                 {i + 1 < currentStep || complete ? <TiTick size={24} /> : i + 1}
//               </div>
//               <p className="text-gray-500">{step}</p>
//             </div>
//           ))}
//         </div>
//         {!complete && (
//           <button type="button" className="btn" onClick={() => { currentStep === steps.length ? setComplete(true) : setCurrentStep((prev) => prev + 1); }} >
//             {currentStep === steps.length ? "Finish" : "Next"}
//           </button>
//         )}
//       </>
//     );
//   };


export default Stepper;