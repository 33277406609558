import { useState, useContext, useEffect } from 'react';
import { AppContext } from '../../../context/AppContext';
import { useTranslation } from 'react-i18next';

export const BarSchedule = () => {
    const [expanded, setExpanded] = useState(false);
    const [state] = useContext(AppContext);
    const [t] = useTranslation("global");
    const [todayOpenTime, setTodayOpenTime] = useState("") // eslint-disable-line
    const [todayCloseTime, setTodayCloseTime] = useState("")
    const [status, setStatus] = useState("")

  // Horários do estabelecimento por dia da semana
    const hours = ['09:00 - 03:00','Closed', '11:00 - 03:00','11:00 - 03:00','11:00 - 03:00','11:00 - 03:00','09:00 - 03:00']
    const days = [`${t(`sunday`)}`, `${t(`monday`)}`, `${t(`tuesday`)}`, `${t(`wednesday`)}`, `${t(`thursday`)}`, `${t(`friday`)}`, `${t(`saturday`)}`];


  // Função para pegar o horário de hoje
    const getCurrentDayHours = () => {
        const today = new Date().getDay();
        return {hours: hours[today]};
    };

    // Função para converter string de horas para minutos totais do dia
    const timeStringToMinutes = (timeString) => {
        const [hours, minutes] = timeString.split(':').map(Number);
        return hours * 60 + minutes;
    };

    useEffect(()=>{
        // Verifica se a hora atual está dentro do horário de trabalho
        const checkIfOpen = () => {
            const { hours } = getCurrentDayHours();

            // Se o estabelecimento estiver fechado
            if (hours === 'Closed') {
                setStatus('Closed');
            return 'Closed';
            }

            const [openTime, closeTime] = hours.split(' - ');
            setTodayOpenTime(openTime)
            setTodayCloseTime(closeTime)

            // Pega a hora atual em minutos
            const now = new Date();
            const currentTimeInMinutes = now.getHours() * 60 + now.getMinutes();

            // Converte as horas de abertura e encerramento para minutos
            const openTimeInMinutes = timeStringToMinutes(openTime);
            const closeTimeInMinutes = timeStringToMinutes(closeTime);

            // Verifica se a hora atual está dentro do intervalo
            if (currentTimeInMinutes >= openTimeInMinutes && currentTimeInMinutes <= closeTimeInMinutes) {
                setStatus('Open');
            } else {
                setStatus('Closed');
            }
        };
        // Constante status que armazena o resultado
        checkIfOpen();

    },[]) // eslint-disable-line

    // Alterna entre expandido e contraído
    const toggleExpand = () => {
        setExpanded(!expanded);
    };

  return (
    <>
        <section className={`transition-transform ease-out duration-300 w-full`}>
            <div className={`${state.darkMode?'':'sections_bg_color sections_text_color'} sm:flex w-full sm:items-center sm:justify-between xl:px-8 dark:bg-gray-800 antialiased`}>
                <div className="text-sm dark:text-gray-400 sm:mb-0 w-full">
                    <div className="flex flex-wrap justify-between my-2">
                        <span className='flex flex-wrap'>
                            {status && (
                                <>
                                    <span className={`w-[12px] h-[12px] ${status==="Open" && "bg-green-500 dark:bg-white"} ${status==="Closed" && "bg-gray-500 dark:bg-white"} rounded-full my-auto mr-2`}></span>
                                    <span className={`${status==="Open" && "text-green-500 mr-2 dark:bg-white"} ${status==="Closed" && "text-gray-900 dark:bg-white"}`}>{status}</span>
                                    { status==="Open" && <span>{`${t(`close_at`)} ${todayCloseTime} h`}</span>}
                                </>
                            )}
                        </span>
                        <span onClick={toggleExpand} className='font-bold text-sm cursor-pointer'> {expanded ? `${t(`hide_schedule`)}` : `${t(`show_schedule`)}`} </span>
                    </div>
                    {expanded && (
                    // Mostra todos os horários da semana
                    <div className='text-center'>
                        {days.map((day, index) => (
                            <div key={index}>
                                <strong>{day}:</strong> {hours[index]}
                            </div>
                        ))}
                    </div>
                    )}
                </div>
            </div>
        </section>
    </>
  );
};

