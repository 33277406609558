import { AppContext } from "../../../context/AppContext";
import { useContext, useEffect } from "react";

export const WellnessServicesFilter = ({productsTypes, activeProducts, setActiveProducts}) => {
    const [state] = useContext(AppContext);

    const handleClick = (productType, event) => {
        event.preventDefault(); // Prevents default behavior, like form submission
        setActiveProducts(productType);
      };

      useEffect(() => {
        if (productsTypes.length > 0) {
          setActiveProducts(productsTypes[0]);
        }
      }, [productsTypes, setActiveProducts]);

      if (productsTypes.length === 0) return null; // Early return if no product types

   return (
        <div className="flex ">
          <div className="flex items-center justify-between space-y-3 md:flex-row md:space-y-0 md:space-x-4 overflow-x-scroll no-scrollbar">
            <div className="flex w-full rounded-md shadow-sm md:w-auto flex-row mb-4" role="group" >
                 <button onClick={(event) => handleClick(productsTypes[0], event)} type="button" className={`${activeProducts === productsTypes[0] ? "bg-blue-200 dark:bg-gray-600" : "bg-white dark:bg-gray-700"} ${ state.darkMode ? "" : "card_title_color card_border_color card_hover_bg_color" } px-3 py-2 text-sm font-medium border rounded-l-lg focus:z-10  dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-primary-500 dark:focus:text-white`} >
                   {productsTypes[0]}
                </button>
                {productsTypes.slice(1, -1).map((productType, index) => (
                    <button key={index} onClick={(event) => handleClick(productType, event)} type="button" className={`${activeProducts === productType ? "bg-blue-200 dark:bg-gray-600" : "bg-white dark:bg-gray-700 "} ${state.darkMode ? "" : "card_title_color card_border_color card_hover_bg_color"} px-4 py-2 text-sm font-medium border focus:z-10  dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-primary-500 dark:focus:text-white`} >
                      {productType}
                    </button>
                ))}
              <button onClick={(event) => handleClick(productsTypes[productsTypes.length - 1], event)} type="button" className={`${activeProducts === productsTypes[productsTypes.length - 1] ? "bg-blue-200 dark:bg-gray-600" : "bg-white dark:bg-gray-700 "} ${ state.darkMode ? "" : "card_title_color card_border_color card_hover_bg_color" } px-4 py-2 text-sm font-medium  border rounded-r-lg focus:z-10 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-primary-500 dark:focus:text-white`} >
              {productsTypes[productsTypes.length-1]}
              </button>
            </div>
          </div>
        </div>  )
}
