import  { useState, useEffect, useContext} from 'react'
import { AppContext } from '../../context/AppContext'

import Logo from '../../assets/logos/Host_GXP-Dashboard-Black.png'
import Logo_dark from '../../assets/logos/Host_GXP-Dashboard.png'
import { Link } from 'react-router-dom'
import { NavbarApps } from './components/NavbarApps'
import { useRefs } from '../../context/RefsContext'

import "../css/Navbar.css"
import { NavbarUser } from './components/NavbarUser'
import { NavbarNotifications } from './components/NavbarNotifications'
import { NavbarSearch } from './components/NavbarSearch'
import { getHostProfile } from '../../services'
import { toast } from 'react-toastify'
import { useCookies } from 'react-cookie'
import { DarkModeButton } from './components/Buttons/DarkModeButton'

export const Navbar = () => {
    const [state, dispatch] = useContext(AppContext);
    const { toggleSidebarRef }= useRefs()
    const { toggleSearchRef }= useRefs()
    const { sidebarRef } = useRefs()
    const { searchSidebarRef } = useRefs()
    const [cookies, setCookie] = useCookies(['sUid', 'profileKey', 'GUid']);
    const [darkMode, setDarkMode]=useState(JSON.parse(localStorage.getItem(`darkMode`)) || false)

    //Nav-bar -> Dropdown Menus Hidden State
    const [hiddenNotificationDropdown, setHiddenNotificationDropdown]=useState(true)
    const [hiddenAppsDropdown, setHiddenAppsDropdown]=useState(true)
    const [hiddenUserDropdown, setHiddenUserDropdown]=useState(true)

    const toggleSearch = ()=>{

      dispatch({type:"TOGGLE_MOBILE_SIDEBAR", payload:!state.hiddenMobileSidebar })

      if (state.hiddenMobileSidebar && searchSidebarRef.current) {
        // Ensure current is a valid HTMLInputElement or HTMLTextAreaElement
        if (searchSidebarRef.current instanceof HTMLInputElement ||
            searchSidebarRef.current instanceof HTMLTextAreaElement) {
          searchSidebarRef.current.select();
        }
      }

      if(hiddenAppsDropdown===false || hiddenUserDropdown===false || hiddenNotificationDropdown===false ){
        setHiddenAppsDropdown(true)
        setHiddenUserDropdown(true)
        setHiddenNotificationDropdown(true)
      }

    }

    const openMobileSidebar = ()=>{
      dispatch({type:"TOGGLE_MOBILE_SIDEBAR", payload:!state.hiddenMobileSidebar})
    }

    function closeMobileSidebar(e){
      if ((sidebarRef.current && !sidebarRef.current.contains(e.target)) && (toggleSidebarRef.current && !toggleSidebarRef.current.contains(e.target)) && (toggleSearchRef.current && !toggleSearchRef.current.contains(e.target))) {
        dispatch({type:"TOGGLE_MOBILE_SIDEBAR", payload:true})
      }
    }

    /**
     * UseEffect that search host info using shortuid and hotel subscriptionKey
     */
    useEffect(() => {
      if(state.currentHotel.subscriptionKey){
      getHostProfile(cookies.sUid,cookies.profileKey,state.currentHotel.subscriptionKey)
      .then((result) => {
        const data = result;
        // Define o user
        dispatch({ type: "SET_USER_DETAILS", payload: data });
        setCookie('profileKey', data.ProfileKey, {path:"/"})
        setCookie('GUid', data.Uid, {path:"/"})
      })
      .catch((error) => {
        toast.error("Unable to get host!");
      })
    }
    },[cookies.sUid,cookies.profileKey,state.currentHotel.subscriptionKey]) // eslint-disable-line

       useEffect(() => {
        if (!state.hiddenMobileSidebar){
        document.addEventListener('mousedown', closeMobileSidebar);
        return () => {
          document.removeEventListener('mousedown', closeMobileSidebar);
        }}

      }, [state.hiddenMobileSidebar===false]);// eslint-disable-line

      /**
       * Funtion that sets buttons Refs that connects with sidebar
       */
      useEffect(()=>{
        dispatch({type:"UPDATE_TOGGLESIDEBARREF", payload:toggleSidebarRef})
        dispatch({type:"UPDATE_TOGGLESEARCHREF", payload:toggleSearchRef})
      },[])// eslint-disable-line

  return (
    <>
    { toggleSidebarRef && searchSidebarRef &&
      <nav className={`${state.darkMode?'':'navbar_border_color navbar_bg_color'} border-b px-4 py-2.5 dark:bg-gray-800 dark:border-gray-700 left-0 right-0 top-0 z-50 fixed`}>
         <div className="flex justify-between items-center">
           <div className="flex justify-start items-center">
             <button onClick={ openMobileSidebar } ref={toggleSidebarRef} data-drawer-target="drawer-navigation" data-drawer-toggle="drawer-navigation" aria-controls="drawer-navigation" className={`${state.darkMode?'':'sidebar_icons_color sidebar_hover_text_color sidebar_hover_bg_color'} p-2 mr-2 rounded-lg cursor-pointer md:hidden focus:ring-2 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white`}>
               <svg aria-hidden="true" className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http:www.w3.org/2000/svg">
                 <path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h6a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd"></path>
               </svg>
               <svg aria-hidden="true" className={`${state.darkMode?'':'sidebar_icons_color sidebar_hover_text_color sidebar_hover_bg_color'} hidden w-6 h-6`} fill="currentColor" viewBox="0 0 20 20" xmlns="http:  www.w3.org/2000/svg">
                 <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path>
               </svg>
               <span className="sr-only">Toggle sidebar</span>
             </button>
             {/* Logo and title */}
             <Link to="/" className="flex items-center justify-between mr-4">
               <img src={darkMode ? Logo_dark : Logo } className="mr-3 h-8 " alt="HHS Logo"/>
               {/* <span className="self-center text-2xl max-md:text-lg font-semibold whitespace-nowrap dark:text-white"></span> */}
             </Link>
             <NavbarSearch />
           </div>
           <div className="flex items-center lg:order-2 max-md:overflow-hidden">
             <button onClick={ toggleSearch } ref={toggleSearchRef} type="button" data-drawer-toggle="drawer-navigation" aria-controls="drawer-navigation" className={`${state.darkMode?'':'navbar_icons_color navbar_hover_text_color navbar_hover_bg_color'} p-2 mr-1 rounded-lg md:hidden dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-700`}>
               <span className="sr-only">Toggle search</span>
               <svg aria-hidden="true" className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http:  www.w3.org/2000/svg">
                 <path clipRule="evenodd" fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"></path>
               </svg>
             </button>
             <DarkModeButton setDarkMode={setDarkMode} darkMode={darkMode} hiddenAppsDropdown={hiddenAppsDropdown} setHiddenAppsDropdown={setHiddenAppsDropdown} hiddenNotificationDropdown={hiddenNotificationDropdown} setHiddenNotificationDropdown={setHiddenNotificationDropdown} hiddenUserDropdown={hiddenUserDropdown} setHiddenUserDropdown={setHiddenUserDropdown} />
             <NavbarNotifications hiddenAppsDropdown={hiddenAppsDropdown} setHiddenAppsDropdown={setHiddenAppsDropdown} hiddenNotificationDropdown={hiddenNotificationDropdown} setHiddenNotificationDropdown={setHiddenNotificationDropdown} hiddenUserDropdown={hiddenUserDropdown} setHiddenUserDropdown={setHiddenUserDropdown} />
             <NavbarApps hiddenAppsDropdown={hiddenAppsDropdown} setHiddenAppsDropdown={setHiddenAppsDropdown} hiddenNotificationDropdown={hiddenNotificationDropdown} setHiddenNotificationDropdown={setHiddenNotificationDropdown} hiddenUserDropdown={hiddenUserDropdown} setHiddenUserDropdown={setHiddenUserDropdown} />
             <NavbarUser hiddenAppsDropdown={hiddenAppsDropdown} setHiddenAppsDropdown={setHiddenAppsDropdown} hiddenNotificationDropdown={hiddenNotificationDropdown} setHiddenNotificationDropdown={setHiddenNotificationDropdown} hiddenUserDropdown={hiddenUserDropdown} setHiddenUserDropdown={setHiddenUserDropdown} />
           </div>
         </div>
       </nav>
    }
    </>
  )

}
