import { useContext } from 'react';
import { AppContext } from '../../../context/AppContext';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const WelcomeMessage = () => {
    const [state] = useContext(AppContext);
    const [t] = useTranslation("global")


  return (
<section className="mb-4">
                <div className={`${state.darkMode?'':'sections_bg_color sections_border_color sections_title_color'} rounded-lg shadow sm:flex sm:items-center sm:justify-between px-4 sm:px-6 xl:px-8 py-3 dark:bg-gray-800 antialiased`}>
                  <div >
                    <p  className="sm:mb-4 pb-2 sm:text-xl max-sm:text-l font-bold dark:text-gray-400">{`${t(`welcome_to_hotelname`)} ${state.currentHotel.name2}`}, {state.userDetails.FirstName} {state.userDetails.LastName}</p>
                  </div>
                  <div className="flex justify-center items-center space-x-1">
                    <Link to="#" data-tooltip-target="tooltip-facebook" className={`${state.darkMode?'':'sections_hover_bg_color'} inline-flex justify-center p-2 rounded-lg cursor-pointer dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600`}>
                        <svg className="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 8 19">
                            <path fillRule="evenodd" d="M6.135 3H8V0H6.135a4.147 4.147 0 0 0-4.142 4.142V6H0v3h2v9.938h3V9h2.021l.592-3H5V3.591A.6.6 0 0 1 5.592 3h.543Z" clipRule="evenodd"/>
                        </svg>
                        <span className="sr-only">Facebook</span>
                    </Link>
                    <div id="tooltip-facebook" role="tooltip" className="inline-block absolute invisible z-10 py-2 px-3 text-sm font-medium text-white rounded-lg shadow-sm opacity-0 transition-opacity duration-300 tooltip dark:bg-gray-700">
                        Like us on Facebook
                        <div className="tooltip-arrow" data-popper-arrow></div>
                    </div>
                    <Link to="#" data-tooltip-target="tooltip-twitter" className={`${state.darkMode?'':'sections_hover_bg_color'} inline-flex justify-center p-2 rounded-lg cursor-pointer dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600`}>
                        <svg className="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                            <path fill="currentColor" d="M12.186 8.672 18.743.947h-2.927l-5.005 5.9-4.44-5.9H0l7.434 9.876-6.986 8.23h2.927l5.434-6.4 4.82 6.4H20L12.186 8.672Zm-2.267 2.671L8.544 9.515 3.2 2.42h2.2l4.312 5.719 1.375 1.828 5.731 7.613h-2.2l-4.699-6.237Z"/>
                        </svg>
                        <span className="sr-only">Twitter</span>
                    </Link>
                    <div id="tooltip-twitter" role="tooltip" className="inline-block absolute invisible z-10 py-2 px-3 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm opacity-0 transition-opacity duration-300 tooltip dark:bg-gray-700">
                        Follow us on Twitter
                        <div className="tooltip-arrow" data-popper-arrow></div>
                    </div>
                  </div>
                </div>
              </section>  )
}
