import { AppContext } from "../../../context/AppContext";
import { useContext, useEffect } from "react";

export const MenuFilter = ({menuTypes, activeMenu, setActiveMenu}) => {
    const [state] = useContext(AppContext);

    const handleClick = (menuType, event) => {
        event.preventDefault(); // Prevents default behavior, like form submission
        setActiveMenu(menuType);
      };

    useEffect(()=>{
        setActiveMenu(menuTypes[0])
    },[menuTypes, setActiveMenu])

  return (
        <div className="flex ">
          <div className="flex items-center justify-between space-y-3 md:flex-row md:space-y-0 md:space-x-4 overflow-x-scroll no-scrollbar">
            <div className="flex w-full rounded-md shadow-sm md:w-auto flex-row mb-4" role="group" >
                 <button onClick={(event) => handleClick(menuTypes[0], event)} type="button" className={`${activeMenu === menuTypes[0] ? "bg-blue-200 dark:bg-gray-600" : "bg-white dark:bg-gray-700"} ${ state.darkMode ? "" : "card_title_color card_border_color card_hover_bg_color" } px-3 py-2 text-sm font-medium border rounded-l-lg focus:z-10  dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-primary-500 dark:focus:text-white`} >
                   {menuTypes[0]}
                </button>
                {menuTypes.slice(1, -1).map((menuType, index) => (
                    <button key={index} onClick={(event) => handleClick(menuType, event)} type="button" className={`${activeMenu === menuType ? "bg-blue-200 dark:bg-gray-600" : "bg-white dark:bg-gray-700 "} ${state.darkMode ? "" : "card_title_color card_border_color card_hover_bg_color"} px-4 py-2 text-sm font-medium border focus:z-10  dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-primary-500 dark:focus:text-white`} >
                      {menuType}
                    </button>
                ))}
              <button onClick={(event) => handleClick(menuTypes[menuTypes.length - 1], event)} type="button" className={`${activeMenu === menuTypes[menuTypes.length - 1] ? "bg-blue-200 dark:bg-gray-600" : "bg-white dark:bg-gray-700 "} ${ state.darkMode ? "" : "card_title_color card_border_color card_hover_bg_color" } px-4 py-2 text-sm font-medium  border rounded-r-lg focus:z-10 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-primary-500 dark:focus:text-white`} >
              {menuTypes[menuTypes.length-1]}
              </button>
            </div>
          </div>
        </div>  )
}
