import { useTranslation } from "react-i18next";
import { AppContext } from "../../../context/AppContext";
import { useContext, useEffect, useState } from "react";
import { WellnessOrdersCard } from "./WellnessOrdersCard";

export const WellnessOrders = () => {

    const [t] = useTranslation("global")
    const [state] = useContext(AppContext);
    const [userAppointments, setUserAppointments]= useState([])
    const [filter, setFilter] = useState('all');


    const handleFilterChange = (newFilter, event) => {
      event.preventDefault(); // Prevents default behavior, like form submission
      setFilter(newFilter);
    };


    const filtererAppointments = (userAppointments||[]).filter((appointment) => {
      if (filter === 'active') return appointment.status === 'active';
      if (filter === 'completed') return appointment.status === 'completed';
      return true; // 'all' or no filter applied
    });

    useEffect(()=>{
      setUserAppointments(state.userLoggedInDetails.appointments)
    },[state.userLoggedInDetails])

  return (
    <div className="max-lg:w-full">
      <section className="flex dark:bg-gray-900">
          <div className="flex items-center justify-between space-y-3 md:flex-row md:space-y-0 md:space-x-4">
            <div className="flex flex-wrap w-full rounded-md shadow-sm md:w-auto flex-row mb-4" role="group" >
              <button onClick={(event) => handleFilterChange('all', event)} type="button" className={`${state.darkMode?'':'card_title_color card_border_color card_hover_bg_color'} px-4 py-2 text-sm font-medium bg-white border rounded-l-lg focus:z-10 focus:ring-2 focus:ring-primary-700 focus:text-primary-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-primary-500 dark:focus:text-white`} >
              {t("all")}
              </button>
              <button onClick={(event) => handleFilterChange('active', event)} type="button" className={`${state.darkMode?'':'card_title_color card_border_color card_hover_bg_color'} px-4 py-2 text-sm font-medium bg-white border focus:z-10 focus:ring-2 focus:ring-primary-700 focus:text-primary-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-primary-500 dark:focus:text-white`} >
              {t("active")}
              </button>
              <button onClick={(event) => handleFilterChange('completed', event)} type="button" className={`${state.darkMode?'':'card_title_color card_border_color card_hover_bg_color'} px-4 py-2 text-sm font-medium bg-white border rounded-r-lg focus:z-10 focus:ring-2 focus:ring-primary-700 focus:text-primary-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-primary-500 dark:focus:text-white`} >
              {t("completed")}
              </button>
            </div>
          </div>
      </section>
      <section className="w-full">
        {filtererAppointments.map((appointment)=>(
          <WellnessOrdersCard key={appointment.id} id={appointment.id} user_id={state.userLoggedInDetails.id} hotel_id={appointment.hotel_id} service_id={appointment.service_id} type={appointment.type} date={appointment.date} status={appointment.status} />
        ))}
      </section>
    </div>
  );
};
