import { useContext } from "react";
import { AppContext } from "../../../context/AppContext";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { ReservationDetailsSkeleton } from "./ReservationDetailsSkeleton";

require("dayjs/locale/pt");


export const ReservationDetails = ({reservation}) => {
    const [state] = useContext(AppContext);
    const [t] = useTranslation("global");

    if(!state.userDetails){
        return <ReservationDetailsSkeleton />
    }

  return (
    <div className="sm:min-w-80 ml-6 mr-3 max-sm:ml-0 max-sm:mt-4 max-sm:w-full max-sm:mr-0">
        <h5 className={`${ state.darkMode ? "" : "card_title_color" } mb-3 text-2xl tracking-tight dark:text-white`} > <b>{`${t(`details`)}`}</b> </h5>
        <p className={`${ state.darkMode ? "" : "card_title_color" } mt-3 font-normal dark:text-white text-lg`} > <b>{`${t(`reservation_number`)}`}</b> </p>
        <p className={`${ state.darkMode ? "" : "card_text_color" } font-normal dark:text-gray-400 text-lg`} > {reservation.resno} </p>
        <p className={`${ state.darkMode ? "" : "card_title_color" } mt-3 font-normal dark:text-white text-lg`} > <b>{`${t(`host`)}`}</b> </p>
        <p className={`${ state.darkMode ? "" : "card_text_color" } font-normal dark:text-gray-400 text-lg`} > {state.userDetails.FirstName}{" "} {state.userDetails.LastName} </p>
        <p className={`${ state.darkMode ? "" : "card_title_color" } mt-3 font-normal dark:text-white text-lg`} > <b>{`${t(`room`)}`}</b> </p>
        <p className={`${ state.darkMode ? "" : "card_text_color" } font-normal dark:text-gray-400 text-lg`} > {reservation.room} </p>
        <div className="flex items-center mt-3">
            <span className={`${ state.darkMode ? "" : "card_title_color" } w-1/2 mb-2 mr-2 font-normal text-lg text-gray-500 dark:text-white`} > <b>{`${t(`check-in`)}`}</b> </span>
            <span className={`${ state.darkMode ? "" : "card_title_color" } mb-2 font-normal text-lg text-gray-500 dark:text-white`} > <b>{`${t(`check-out`)}`}</b> </span>
        </div>
        <div className="flex items-center">
            <span className={`${ state.darkMode ? "" : "card_text_color" } w-1/2 text-lg mb-2 font-normal dark:text-gray-400`} >{dayjs(reservation.checkin).format(t("dayjs_format")) } </span>
            <span className={`${ state.darkMode ? "" : "card_text_color" } flex text-lg mb-2 mr-2 align-middle font-normal dark:text-gray-400`} >
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right align-middle" viewBox="0 0 16 16" >
                    <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8" />{" "}
                </svg>
            </span>
            <span className={`${ state.darkMode ? "" : "card_text_color" } mb-2 text-lg font-normal dark:text-gray-400`} > {dayjs(reservation.checkout).format(t("dayjs_format")) } </span>
        </div>
    </div>  )
}
